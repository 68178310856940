export const SELECT_TABLE_ORDER = 'SELECT_TABLE_ORDER'
export const SELECT_TABLE_SELECTED_DATE = 'SELECT_TABLE_SELECTED_DATE'
export const SELECT_TABLE_SELECTED_REGION = 'SELECT_TABLE_SELECTED_REGION'
export const SELECT_TABLE_SELECTED_CLIENT = 'SELECT_TABLE_SELECTED_CLIENT'
export const UPDATE_RATE_TABLES = 'UPDATE_RATE_TABLES'
export const UPDATE_VIEW = 'UPDATE_VIEW'
export const UPDATE_STEMS = 'UPDATE_STEMS'
export const UPDATE_ACTIONS = 'UPDATE_ACTIONS'
export const UPDATE_CONTRACTORS =  'UPDATE_CONTRACTORS'
export const UPDATE_RATE_CONDITIONS = 'UPDATE_RATE_CONDITIONS'
export const UPDATE_ROLE = 'UPDATE_ROLE'
export const SET_SELECTED_VIEW = 'SET_SELECTED_VIEW'
export const ADD_COUNT_FOR_FETCH = 'ADD_COUNT_FOR_FETCH'