
import {
    getDoc, doc, updateDoc
} from 'firebase/firestore'

class ContactlessDelivery {
    collectionInstance: any;
    constructor(collectionInstance: any) {
        this.collectionInstance = collectionInstance
    }

        
    /**
        * @description 
        * @param  {string} docId Document Id
        * @param  {any} data Data to be Updated or Added
        * @returns reference 
    */

    doc(docId: string) {
        return ({
            update: async (data: any) => {
                try {
                    const ref = doc(this.collectionInstance, docId)
                    return await updateDoc(ref, data)
                } catch (err) {
                    console.error(err)
                    return
                }
            },
            get: async () => {
                const ref = doc(this.collectionInstance, docId)
                const document = await getDoc(ref)
                return document
            }
        })
    }
}

export default (collection: any) => {
    return new ContactlessDelivery(collection)
}