import {
    collection,
    addDoc,
    query,
    getDoc,
    doc,
    updateDoc,
    where,
    onSnapshot,
    getDocs,
    limit,
    startAfter,
    orderBy,
    setDoc,
    deleteDoc,
    DocumentReference,
    CollectionReference,
    Query,
    or,
    and    
} from 'firebase/firestore'

import * as moment from 'moment'

class Order {
    collectionInstance: any
    constructor(collectionInstance: any) {
        this.collectionInstance = collectionInstance
    }

    doc(docId?: string): any {
        if (!docId) {
            const generatedId = doc(this.collectionInstance).id;
            return {
              id: generatedId,
              set: async (data: any, options?: any) => {
                const ref = doc(this.collectionInstance, generatedId);
                return setDoc(ref, data, options);
              },
              // ...
            };
        } else {
            return {
                set: async (data: any, options?: any) => {
                    const ref = doc(this.collectionInstance, docId)
                    return setDoc(ref, data, options)
                },
                update: async (data: any) => {
                    try {
                        const ref = doc(this.collectionInstance, docId)
                        return await updateDoc(ref, data)
                    } catch (err) {
                        console.error(err)
                        return
                    }
                },
                collection: (collectionId: string) => ({
                    add: async (data: any) => {
                        const ref = doc(this.collectionInstance, docId)
                        const coll = collection(ref, collectionId)
                        return addDoc(coll, data)
                    },
                }),
                get: async () => {
                    const ref = doc(this.collectionInstance, docId)
                    const document = await getDoc(ref)
                    return document
                },
                delete: async () => {
                    const ref = doc(this.collectionInstance, docId)
                    await deleteDoc(ref)
                    return true
                }
            }
        }
    }

    where(fieldPath: string, opStr: any, value: any) {
        return {
            orderBy: (fieldOrder: string, direction: any) => ({
                startAfter: (dataType: any) => ({
                    limit: (numLimit: number) => ({
                        get: async () => {
                            const q = query(
                                this.collectionInstance,
                                where(fieldPath, opStr, value),
                                orderBy(fieldOrder, direction),
                                startAfter(dataType),
                                limit(numLimit)
                            )
                            const querySnapshot = await getDocs(q)
                            return querySnapshot
                        },
                    }),
                }),
            }),
            get: async () => {
                const q = query(this.collectionInstance, where(fieldPath, opStr, value))
                const querySnapshot = await getDocs(q)
                return querySnapshot
            },
            where: (innerFieldPath: string, innerOpStr: any, innerValue: any) => {
                return {
                    limit: (numLimit: number) => ({
                        get: async () => {
                            const q = query(
                                this.collectionInstance,
                                where(fieldPath, opStr, value),
                                where(innerFieldPath, innerOpStr, innerValue),
                                limit(numLimit)
                            )
                            const querySnapshot = await getDocs(q)
                            return querySnapshot
                        },
                    }),
                    where: (nestedInnerFieldPath: string, nestedInnerOpStr: any, nestedInnerValue: any) => {
                        return {
                                get: async () => {
                                    const q = query(
                                        this.collectionInstance,
                                        where(fieldPath, opStr, value),
                                        where(innerFieldPath, innerOpStr, innerValue),
                                        where(nestedInnerFieldPath, nestedInnerOpStr, nestedInnerValue)
                                    )
                                    const querySnapshot = await getDocs(q)
                                    return querySnapshot
                                },
                        }
                    },
                }
            },
        }
    }

    limit(numLimit: number) {
        return {
            get: async () => {
                const q = query(this.collectionInstance, limit(numLimit))
                const querySnapshot = await getDocs(q)
                return querySnapshot
            },
        }
    }

    whereQuery(fieldPath: string, opStr: any, value: any) {
        const q = query(this.collectionInstance, where(fieldPath, opStr, value))
        return q
    }

    async add(data: any) {
        try {
            return addDoc(this.collectionInstance, data)
        } catch (err) {
            console.error(err)
            return
        }
    }

    // DocumentReference | CollectionReference | Query
    async onSnapshot(ref: any, observer: any) {
        return onSnapshot(ref, observer)
    }

    async chatCollectionSnapshot(docId: any, observer: any) {
        const ref = doc(this.collectionInstance, docId)
        const coll = collection(ref, 'CHAT')
        const q = query(coll, orderBy('createdAt'))
        return onSnapshot(q, observer)
    }

    async getHistory(docId: any, pageLimit:number) {
        const ref = doc(this.collectionInstance, docId)
        const coll = collection(ref, 'HISTORY')
        const q = query(coll, orderBy('details.timestamp', 'desc'), limit(pageLimit))
        const querySnapshot = await getDocs(q)
        return querySnapshot
    }

    async getHistoryPagination(docId: any, pageLimit:number, latestDoc: any) {
        const ref = doc(this.collectionInstance, docId)
        const coll = collection(ref, 'HISTORY')
        const q = query(coll, orderBy('details.timestamp', 'desc'), startAfter(latestDoc), limit(pageLimit))
        const querySnapshot = await getDocs(q)
        return querySnapshot
    }

    async getOrderToFromDate(fromDate: Date, toDate: Date, dataField: string, numLimit = 100) {
        const parseFromDate = moment.utc(fromDate).toDate()
        const parseToDate = moment.utc(toDate).toDate()
        const q = query(
            this.collectionInstance,
            where(`fields.${dataField}`, '>=', parseFromDate),
            where(`fields.${dataField}`, '<', parseToDate),
            limit(numLimit)
        )
        const querySnapshot = await getDocs(q)
        return querySnapshot
    }

    async getByQueries(
        queries: {
            fieldPath: string
            opStr: any
            value: any
        }[]
    ) {

        const toWhereQueries = queries.map((query:any) => {
            
            if(query?.or?.length === 2) {
                return or(
                        where(query.or[0].fieldPath, query.or[0].opStr, query.or[0].value),
                        where(query.or[1].fieldPath, query.or[1].opStr, query.or[1].value)
                    )
            }
            
            return where(query.fieldPath, query.opStr, query.value)
        })

        const q = query(this.collectionInstance, and(...toWhereQueries))
        return await getDocs(q)
    }

    async get() {
        try {
            const q = query(this.collectionInstance)
            const snapshot = await getDocs(q)
            return snapshot
        } catch (er) {
            console.log(er)
        }
        // if (this.hasDoc) {

        // } else {
        //     if (this.queryActions.length > 0) {
        //         const queryLine: any[] = []
        //         this.queryActions.forEach((action: any) => {
        //             if (action.type === 'where') {
        //                 const { fieldPath, opStr, value } = action.data
        //                 queryLine.push(where(fieldPath, opStr, value))
        //             } else if (action.type === 'limit') {
        //                 const { numLimit } = action.data
        //                 queryLine.push(limit(numLimit))
        //             } else if (action.type === 'orderBy') {
        //                 const { fieldOrder, direction } = action.data
        //                 queryLine.push(orderBy(fieldOrder, direction))
        //             }
        //         })
        //         const q = query(this.collectionInstance , ...queryLine)
        //         const querySnapshot = await getDocs(q)
        //         return querySnapshot
        //     } else {
        //         try {
        //             const q = query(this.collectionInstance)
        //             const snapshot = await getDocs(q)
        //             return snapshot
        //         } catch(er) {
        //             console.log(er)
        //         }
        //     }
        // }
    }
}

export default (collection: any) => {
    return new Order(collection)
}
