import React, { useState } from 'react'
import { useSnackbar } from 'react-simple-snackbar'
import { db } from '../../../../../../../services/firebase/firebaseConfig'
import BillingModuleDropdown from '../../BillingModuleDropdown'
import { useSelector } from 'react-redux'
import Selector from '../../../../../../../components/Select/Selector'
import { doc, getDoc, setDoc } from 'firebase/firestore'

const PostalCode = () => {
    const { clients } = useSelector((state: any) => state.user)
    const [selectedClient, setSelectedClient] = useState<any>(null)
    const [postalCode, setPostalCode] = useState('')

    const [selectedPostalCode, setSelectedPostalCode] = useState<any>(null)

    const [isLoading, setIsLoading] = useState(false)
    const [openSnackbar] = useSnackbar()

    const handleFindPostalCode = async () => {
        setIsLoading(true)
        try {
            const path = `POSTALCODES_BILLING_FEES/${selectedClient?.label?.replace('***', '---')}/postal_codes`

            const trimmedPostalCode = postalCode?.toUpperCase()?.trim()?.replace(' ', '')

            const postalCodeDocRef = doc(db, path, trimmedPostalCode)

            const postalCodeDoc = await getDoc(postalCodeDocRef)

            if (postalCodeDoc.exists()) {
                const data = postalCodeDoc.data()
                setSelectedPostalCode({
                    ...data,
                    id: postalCodeDoc.id,
                })
            } else {
                setSelectedPostalCode({
                    id: trimmedPostalCode,
                    FSA: trimmedPostalCode.slice(0, 3),
                    Province: '',
                    Price: '',
                })

                openSnackbar('No matching Postal Code found. You can add a new one.')
            }
        } catch (error) {
            console.error('Error fetching postal code:', error)
            openSnackbar('Error fetching postal code. Please try again.')
        }

        setIsLoading(false)
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        try {
            const path = `POSTALCODES_BILLING_FEES/${selectedClient?.label?.replace('***', '---')}/postal_codes`

            const { Province, Price } = selectedPostalCode

            if (!Province || !Price) {
                openSnackbar('Province and Price are required')
                setIsLoading(false)
                return
            }

            const docRef = doc(db, path, selectedPostalCode.id)
            await setDoc(
                docRef,
                {
                    ...selectedPostalCode,
                },
                { merge: true }
            )

            openSnackbar('Successfully updated')
            setSelectedPostalCode(null)
            setPostalCode('')
        } catch (error) {
            console.error('Error submitting postal code:', error)
            openSnackbar('Error updating postal code. Please try again.')
        }
        setIsLoading(false)
    }

    const isFindButtonDisabled = !selectedClient || postalCode.trim() === ''

    return (
        <div className="notification is-white">
            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <div className="is-4 has-text-left">
                            <BillingModuleDropdown />
                        </div>
                    </div>
                </div>
            </div>
            <div className="content center" style={{ textAlign: 'left' }}>
                <div className="notification is-light">
                    <p className="title is-size-6">Postal Code</p>

                    <div className="columns">
                        <div className="column is-5 mx-1">
                            <Selector
                                options={clients}
                                defaultSelected={selectedClient}
                                onChange={setSelectedClient}
                                isGrouped={false}
                                isMultiSelect={false}
                                placeholder="Select Client"
                            />
                        </div>
                        <div className="column is-3">
                            <input
                                className="input is-small is-rounded  "
                                type="text"
                                placeholder="Type postal code"
                                value={postalCode}
                                onChange={(e) => setPostalCode(e.target.value)}
                            />
                        </div>
                        <div className="column is-3">
                            <button
                                className={`button is-success is-small mx-1 is-rounded ${
                                    isLoading ? 'is-loading' : ''
                                }`}
                                onClick={handleFindPostalCode}
                                disabled={isFindButtonDisabled}
                                style={{ height: '32px', fontSize: '14px' }}
                            >
                                Find
                            </button>
                        </div>
                    </div>

                    {selectedPostalCode && (
                        <div className="content" style={{ marginTop: 30 }}>
                            <div className="field">
                                <label className="label is-size-7">Postal Code</label>
                                <input
                                    className="input is-small"
                                    type="text"
                                    placeholder="Postal Code"
                                    value={selectedPostalCode?.id}
                                    disabled={true}
                                />
                            </div>
                            <div className="field">
                                <label className="label is-size-7">FSA</label>
                                <input
                                    className="input is-small"
                                    type="text"
                                    value={selectedPostalCode?.FSA}
                                    disabled={true}
                                />
                            </div>
                            <div className="field">
                                <label className="label is-size-7">Province</label>
                                <input
                                    className="input is-small"
                                    placeholder="Province"
                                    type="text"
                                    value={selectedPostalCode?.Province}
                                    onChange={(e) => {
                                        setSelectedPostalCode({
                                            ...selectedPostalCode,
                                            Province: e.target.value,
                                        })
                                    }}
                                />
                            </div>
                            <div className="field">
                                <label className="label is-size-7">Price</label>
                                <input
                                    className="input is-small"
                                    type="number"
                                    placeholder="Billing Amount"
                                    value={selectedPostalCode?.Price}
                                    onChange={(e) => {
                                        setSelectedPostalCode({
                                            ...selectedPostalCode,
                                            Price: parseFloat(e.target.value),
                                        })
                                    }}
                                />
                            </div>
                            <div className="mt-5 has-text-right">
                                <button
                                    className={`button mx-1 is-warning is-small is-rounded  ${
                                        isLoading ? 'is-loading' : ''
                                    }`}
                                    onClick={() => {
                                        setSelectedPostalCode(null)
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className={`button is-success is-small is-rounded ${isLoading ? 'is-loading' : ''}`}
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default React.memo(PostalCode)
