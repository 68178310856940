import {
    addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc
} from 'firebase/firestore'


class TemplateHeader {
    collectionInstance: any
    constructor(collectionInstance: any) {
        this.collectionInstance = collectionInstance
    }
    doc(docId?: string): any {
        if (!docId) {
            return addDoc(this.collectionInstance, {}).then((data) => data)
        } else {
            return {
                set: async (data: any, options?: any) => {
                    const ref = doc(this.collectionInstance, docId)
                    return setDoc(ref, data, options)
                },
                collection: (collectionId: string) => ({
                    add: async (data: any) => {
                        const ref = doc(this.collectionInstance, docId)
                        const coll = collection(ref, collectionId)
                        return addDoc(coll, data)
                    },
                }),
                get: async () => {
                    const ref = doc(this.collectionInstance, docId)
                    const document = await getDoc(ref)
                    return document
                },
                delete: async () => {
                    const ref = doc(this.collectionInstance, docId)
                    const document = await deleteDoc(ref)
                    return document
                },
            }
        }
    }

    async add(data: any) {
        try {
            return addDoc(this.collectionInstance, data)
        } catch (err) {
            console.error(err)
            return
        }
    }

    async get() {
        try {
            const q = query(this.collectionInstance)
            const snapshot = await getDocs(q)
            return snapshot
        } catch (er) {
            console.log(er)
        }
    }
}

export default (collection: any) => new TemplateHeader(collection)
