import React, { createContext } from 'react'

export const TableContext = createContext({
    rateTables: {}, //TBD: It would be better to create this as Map. Becuase map is iterable and have other benefits like size()
    selectedTableOrder: [],
    selectedDate: { from: null, to: null },
    selectedRegion: [],
    selectedClient: [],
    allStems: new Map<string,[]>(),
    actions: {},
    role: {},
    contractorsList: [],
    rateConditions: [],
    selectedView: null,
    countForFetch: 0,
})