import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { and, doc, getDocs, or, orderBy, query, setDoc, where } from 'firebase/firestore'
import {
    getCountForQuery,
    getOrdersCollection,
    getProjectCollection,
} from '../../../../services/firebase/firebaseConfig'

const initialState = {
    id: null,
    stores: [],
    storesMap: {},
    isAllDistributionZones: false, 
    userDistributionZoneIds: [],
    isAllClients: false,
    userClientIds: [],
    clients: [],
    distributionzones: [],
    distributionzonesForReverse: [],
    regions: [],
    regionsMap: {},
    stemsList: [],
    stemsMap: {},
    actions: {},
    role: {},
    needToCheckDispatch: null,
    email: null,
    userDataLoaded: false,
    completedGuidesWalkthrough: false,
    isDarkTheme: false,
    // validAccess: fetchedUser ? 'VALID_EMAIL' : 'INVALID_EMAIL',
    // visibilityThreshold: fetchedUser ? fetchedUser.visibilityThreshold : null,
    // countryCode: fetchedUser ? fetchedUser.countryCode : null,
    firstName: null,
    // lastName: null,
    // phone: null,
    // driverProfileDetails: null,
    // isDriver: false,
    // isSBCustomer: false,
    // isVendor: false,
}

const fetchAuthorizedDistributionZones = async (roleTitle, userDistributionZoneIds, isAllDistributionZones): Promise<any> => {
    if (!userDistributionZoneIds && !isAllDistributionZones) return Promise.resolve([])

    const chunkSize = 10

    const queryArray: any = []
    
    if(roleTitle === "SUPER_ADMIN" || isAllDistributionZones) {
        queryArray.push(
            getDocs(
                query(
                    getProjectCollection(`${process.env.REACT_APP_COLLECTION_DISTRIBUTIONZONES}`),
                    orderBy('DistributionZoneName', 'asc')
                )
            )
        )
    } else {
    const distributionZoneList = [...userDistributionZoneIds]

    while (distributionZoneList.length > 0) {
        const chunk = distributionZoneList.splice(0, chunkSize)
        queryArray.push(
            getDocs(
                query(
                    getProjectCollection(`${process.env.REACT_APP_COLLECTION_DISTRIBUTIONZONES}`),
                    where('DistributionZoneId', 'in', chunk),
                    orderBy('DistributionZoneName', 'asc')
                )
            )
        )
    }
    }
    try {
        const results = await Promise.all(queryArray)
        return results.flatMap((q: any) => [...q.docs])
    } catch (error) {
        console.error('Error fetching zones:', error)
        return []
    }
}

const fetchAuthorizedClients = async (roleTitle, userClientIds, isAllClients): Promise<any> => {
    if (!userClientIds && !isAllClients) return Promise.resolve([])

    const chunkSize = 10

    const queryArray: any = []

    if(roleTitle === "SUPER_ADMIN" || isAllClients) {
        queryArray.push(
            getDocs(
                query(
                    getProjectCollection(`${process.env.REACT_APP_COLLECTION_CLIENTS}`)
                )
            )
        )
    } else {

    const clientIdList = [...userClientIds]

    while (clientIdList.length > 0) {
        const chunk = clientIdList.splice(0, chunkSize)
        queryArray.push(
            getDocs(
                query(getProjectCollection(`${process.env.REACT_APP_COLLECTION_CLIENTS}`), where('id', 'in', chunk))
            )
        )
    }}
    try {
        const results = await Promise.all(queryArray)
        return results.flatMap((q: any) => [...q.docs])
    } catch (error) {
        console.error('Error fetching zones:', error)
        return []
    }
}

// const countNeedToCheckDispatch = async (distributionzones, clients, roleName): Promise<any> => {
//     //TBD - need to decide which role should be able to see this or which action should be able to
//     // if(roleName !== 'Dispatch') return null;

//     const distributionZoneNames = distributionzones.map((d: any) => d.data().DistributionZoneName)
//     const clientNames = clients.map((d: any) => ({
//         BusinessUnit: d.data().businessUnit,
//         SubBusinessUnit: d.data().subBusinessUnit,
//     }))

//     const queryArray: any[] = []

//     distributionZoneNames.forEach((zone) => {
//         clientNames.forEach((client) => {
//             queryArray.push(
//                 query(
//                     getProjectCollection('TMS_ORDERS'),
//                     and(
//                         where('fields.DistributionZone', '==', zone),
//                         where('fields.Vendor.BusinessUnit', '==', client.BusinessUnit),
//                         where('fields.Vendor.BusinessSubUnit', '==', client.SubBusinessUnit),
//                         or(
//                             where('fields.CustomerRequestInfo.OrderStatus', '==', 'Customer Requesting Reschedule'),
//                             where('fields.OrderStatus', '==', 'Pending')
//                         )
//                     )
//                 )
//             )
//         })
//     })

//     const results = await Promise.all(queryArray.map(async (q) => await getCountForQuery(q)))

//     let totalCount = 0

//     results.forEach((r: any) => {
//         totalCount += r
//     })

//     return totalCount
// }

export const updateGuideWalkthrough: any = createAsyncThunk(
    'user/updateGuideWalkthrough',
    async (arg, { getState }) => {
        try {
            /* updating user with completedGuidesWalkthrough state if user skips it or completes the guide */
            const state: any = getState()
            const { user } = state
            /* user is in USERS collection , we update the firestore document  */
            const userDoc = doc(getProjectCollection(`${process.env.REACT_APP_COLLECTION_USERS}`), user.id)
            return await setDoc(userDoc, { completedGuidesWalkthrough: true }, { merge: true })
        } catch (error) {
            console.error('Error updating guide walkthrough:', error)
            return Promise.reject(error)
        }
    }
)

export const fetchUserData: any = createAsyncThunk('user/fetchUserData', async (arg, { getState }) => {
    try {
        const state: any = getState()
        const { user } = state
        const [distributionzones, regionDocs, clients] = await Promise.all([
            /* returning only dist zones that user is authorized to have access to */
            fetchAuthorizedDistributionZones(user.role.name, user.userDistributionZoneIds, user.isAllDistributionZones),
            getOrdersCollection('REGIONS').get(),
            fetchAuthorizedClients(user.role.name, user.userClientIds, user.isAllClients),
        ])

        // const countNeedToCheck = await countNeedToCheckDispatch(distributionzones, clients, user.role.name)

         const distributionZones: any[] = []
        let stemsMap = {}
        const regions = new Set()
        const reverseZones: any[] = []
        const storeList: any[] = []
        const stemsList: any[] = []
        let storesMap = {}

        const distributionZoneIds = distributionzones.map((d: any) => d.id)

        distributionzones
            ?.sort((a, b) => (a.data().DistributionZoneName || '').localeCompare(b.data().DistributionZoneName || ''))
            .forEach((d: any) => {

                if (d.data()?.DistributionZoneType === 2) {

                    stemsMap = {
                        ...stemsMap,
                        [d.data().Region]: stemsMap[d.data().Region] ? 
                        [...stemsMap[d.data().Region], { label: d.data().DistributionZoneName, value: d.id }] 
                        : [{ label: d.data().DistributionZoneName, value: d.id }]
                    }
                    stemsList.push({
                        label: `STEM-${d.data().DistributionZoneName}`,
                        value: {id: d.id, Region: d.data().Region},
                    })
                } else if(d.data()?.DistributionZoneType === 3) {
                    storeList.push({
                        label: `STORE-${d.data().DistributionZoneName}`,
                        value: {id: d.id, Region: d.data().Region, Client: d.data().Vendor},
                    })

                    storesMap = {
                        ...storesMap,
                        [d.id]: d.data().DistributionZoneName
                    }


                } else {
                    const element = {
                        label: d.data().DistributionZoneName,
                        value: {
                            id: d.id,
                            ...d.data(),
                            Center: {
                                _lat: d.data()?.Center?._lat,
                                _long: d.data()?.Center?._long,
                            }, //Firebase GeoPoint is not serializable
                        },
                    }

                    distributionZones.push(element)

                    if (d.data()?.IsForReverse) {
                        reverseZones.push(element)
                    }
                } 
                regions.add(d.data().Region)
            })

        const regionsArray: any[] = []
        let regionsMap = {}

        ;(regionDocs?.docs || []).forEach((r: any) => {
            if (regions.has(r.id)) {
                regionsArray.push({
                    value: { id: r.id, ...r.data() },
                    label: r.data().name,
                })

                regionsMap = {
                    ...regionsMap,
                    [r.id]: r.data().name.toUpperCase(),
                }
            }
        })

        const clientIds = clients.map((d: any) => d.id)

        const clientsArray = clients
            ?.map((d: any) => ({
                label: `${d.data().businessUnit}***${d.data().subBusinessUnit}`,
                value: { id: d.id, ...d.data() },
            }))
            .sort((a, b) => a.label.localeCompare(b.label))

        return {
            clients: clientsArray,
            stemsMap: stemsMap,
            stores: storeList,
            distributionzones: distributionZones,
            regions: regionsArray,
            regionsMap: regionsMap,
            needToCheckDispatch: false,
            reverseZones: reverseZones,
            stemsList: stemsList,
            storesMap: storesMap,
            clientIds: clientIds,
            distributionZoneIds: distributionZoneIds,
        }
    } catch (error) {
        console.error('Error fetching user data:', error)
        return Promise.reject(error)
    }
})

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        toggleUserTheme: (state) => {
            state.isDarkTheme = !state.isDarkTheme
        },
        updateRole: (state, action: any) => {
            state.role = action.payload
        },
        updateActions: (state, action: any) => {
            state.actions = action.payload
        },
        updateUserDistributionZoneIds: (state, action: any) => {
            state.userDistributionZoneIds = action.payload
        },
        initUserData: (state, action) => {
            state.role = action.payload.role
            state.isAllDistributionZones = action.payload.isAllDistributionZones
            state.userDistributionZoneIds = action.payload.userDistributionZoneIds
            state.isAllClients = action.payload.isAllClients
            state.isDarkTheme = action.payload.isDarkTheme
            state.userClientIds = action.payload.userClientIds
            state.email = action.payload.email
            state.firstName = action.payload.firstName
            state.id = action.payload.id
            state.completedGuidesWalkthrough = action.payload.completedGuidesWalkthrough
        },
        resetUserData: (state) => {
            state.id = null
            state.role = ''
            state.userDistributionZoneIds = []
            state.userClientIds = []
            state.email = null
            state.firstName = null
            state.stores = []
            state.distributionzones = []
            state.distributionzonesForReverse = []
            state.clients = []
            state.regions = []
            state.regionsMap = {}
            state.actions = {}
            state.stemsMap = {}
            state.needToCheckDispatch = null
            state.userDataLoaded = false
            state.userDataLoaded = false
            state.completedGuidesWalkthrough = false
            state.storesMap = {}
            state.stemsList = []
            state.isDarkTheme = false
        },
        setUserDataLoaded: (state, action) => {
            state.userDataLoaded = action.payload
        },
    },
    extraReducers: {
        [fetchUserData.pending]: (state) => {
            state.userDataLoaded = false
        },
        [updateGuideWalkthrough.fulfilled]: (state) => {
            state.completedGuidesWalkthrough = true
        },
        [fetchUserData.fulfilled]: (state, { payload }) => {
            state.stemsMap = payload.stemsMap
            state.distributionzones = payload.distributionzones
            state.distributionzonesForReverse = payload.reverseZones
            state.clients = payload.clients
            state.regions = payload.regions
            state.regionsMap = payload.regionsMap
            state.needToCheckDispatch = payload.needToCheckDispatch
            state.userDataLoaded = true
            state.stores = payload.stores
            state.storesMap = payload.storesMap
            state.stemsList = payload.stemsList
            state.userClientIds = payload.clientIds
            state.userDistributionZoneIds = payload.distributionZoneIds
        },
        [fetchUserData.rejected]: (state) => {
            state.userDataLoaded = false
        },
    },
})

export const {
    updateRole,
    updateUserDistributionZoneIds,
    initUserData,
    resetUserData,
    toggleUserTheme,
    updateActions,
    setUserDataLoaded,
} = userSlice.actions

export default userSlice.reducer
