import { UPDATE_AUTH_STATE, SET_DID_SET_INTERVAL_RUN, UPDATE_PORTAL } from './userActions.types'
import { auth, usersCollection } from '../../../../services/firebase/firebaseConfig'
// let intervalCheck: any
export const UserAction = {
    setDidSetIntervalRun: (dispatch: any, value: any) => {
        dispatch({
            type: SET_DID_SET_INTERVAL_RUN,
            payload: value,
        })
    },
    updatePortal: (dispatch: any, value: any) => {
        dispatch({
            type: UPDATE_PORTAL,
            payload: value,
        })
    },
    updateUser: async (dispatch: any, value: any) => {
        const user = value
        let displayName = ''
        let countryCode = 1
        let email = ''
        let firstName = ''
        let isDriver = false
        let isSBCustomer = false
        let isVendor = false
        let lastName = ''
        let phone = ''
        let role = ''
        let stores = ['']
        let driverProfileDetails = ''
        let clients = ''
        let visibilityThreshold = ''
        let regions = ['']
        const defaultUserPayload = {
            user: null,
            displayName,
            countryCode,
            email,
            firstName,
            isDriver,
            isSBCustomer,
            isVendor,
            lastName,
            phone,
            role,
            stores,
            driverProfileDetails,
            clients,
            visibilityThreshold,
            regions,
        }
        if (!user) {
            dispatch({
                type: UPDATE_AUTH_STATE,
                payload: defaultUserPayload,
            })
            return
        }

        await Promise.all([user.getIdTokenResult(true), usersCollection.getByQuery('email', '==', user?.email)]).then(
            (results) => {
                const claims = results[0] && results[0].claims
                const data: any = results[1] && results[1]?.docs[0]?.data()
                const _distributionZones = data?.distributionZones
                const _stores = data?.stores

                if (!claims) {
                    dispatch({
                        type: UPDATE_AUTH_STATE,
                        payload: defaultUserPayload,
                    })
                }
                displayName = user.displayName
                countryCode = claims.countryCode
                email = claims.email
                firstName = claims.firstName
                isDriver = claims.isDriver
                isSBCustomer = claims.isSBCustomer
                isVendor = claims.isVendor
                lastName = claims.lastName
                phone = claims.phone
                role = claims.role
                driverProfileDetails = claims.driverProfileDetails
                clients = claims.clients
                visibilityThreshold = claims.visibilityThreshold

                stores = _stores
                regions = _distributionZones

                dispatch({
                    type: UPDATE_AUTH_STATE,
                    payload: {
                        user,
                        displayName,
                        countryCode,
                        email,
                        firstName,
                        isDriver,
                        isSBCustomer,
                        isVendor,
                        lastName,
                        phone,
                        role,
                        stores,
                        driverProfileDetails,
                        clients,
                        visibilityThreshold,
                        regions,
                    },
                })
            }
        )
    },
    getIdToken: async () => await auth.currentUser?.getIdToken(true),
    checkUserActivity: (dispatch: any, currentState: any, value: any) => {
        // const { lastLoginTime, expiry } = value
        if (process.env.REACT_APP_CLIENT === 'BESTBUY') {
            if (!currentState.didSetIntervalRun) {
                dispatch({
                    type: SET_DID_SET_INTERVAL_RUN,
                    payload: true,
                })
                /* if lastActiveTime was not valid, then compare Date.now to last login time */
                /* if lastActiveTime was valid, then compare Date.now to lastActiveTime  */
                // const compareActiveTime = (lastLoginTime: number, expiry: number) => {
                //   const lastActiveTime = (localStorage.getItem('lastActiveTime') && parseInt(localStorage.getItem('lastActiveTime') as string)) || null
                //   let isUserSessionValid: string
                //   if (!lastActiveTime) {
                //     isUserSessionValid = expiry > (Date.now() - lastLoginTime) ? "valid" : "invalid"
                //   } else {
                //     isUserSessionValid = expiry > (Date.now() - lastActiveTime) ? "valid" : "invalid"
                //   }
                //   if (isUserSessionValid === 'invalid') {
                //     // commit('setDidSetIntervalRun', false)
                //     dispatch({
                //         type: SET_DID_SET_INTERVAL_RUN,
                //         payload: false
                //     })
                //     // commit('resetStorageAndInterval')
                //     // firebase.auth.signOut()
                //   }
                // }
                //     intervalCheck = setInterval(() => {
                //       compareActiveTime(lastLoginTime, expiry)
                //     }, 300000) // check every 5 min 300000
            }
        }
    },
}
