import React from 'react'

const GuideWelcome = () => {
    return (
        <section>
            <div className="card-content has-text-left">
                <div>
                    <div>
                        <h1 className="title is-4">Welcome to Shyftbase!</h1>
                        <p className="subtitle">
                            Thank you for choosing Shyftbase. 
                            <br /> 
                            We are excited to have you on board.
                        </p>
                    </div>
                </div>
                <br />
                <p>Here are a few steps to get you started:</p>
                <ul>
                    <li>
                        <span className="icon">
                            <i className="fas fa-fw fa-play-circle"></i>
                        </span>
                        <span>
                            <strong>Take a Tour:</strong> We recommend taking a brief guided tour to learn the
                            platform&apos;s functionalities.
                        </span>
                    </li>
                    <li>
                        <span className="icon">
                            <i className="fas fa-fw fa-compass"></i>
                        </span>
                        <span>
                            <strong>Explore Your Dashboard:</strong> Get familiar with the layout and discover the
                            features available to you.
                        </span>
                    </li>
                    <li>
                        <span className="icon">
                            <i className="fas fa-fw fa-book"></i>
                        </span>
                        <span>
                            <strong>Access Resources:</strong> Visit our Help Center for tutorials, FAQs, and other
                            valuable resources.
                        </span>
                    </li>
                </ul>
                <br />
                <p>
                    Our goal is your success. If you have any questions or need assistance, feel free to reach out to
                    our friendly support team at <a className="has-text-info" href={`mailto:support@shyftbase.com`}>support@shyftbase.com</a>.
                </p>
                <br />
                <p>Thank you</p>
                <p>The Shyftbase Team</p>
            </div>
        </section>
    )
}

export default React.memo(GuideWelcome)
