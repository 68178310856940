
import {
    collection, addDoc, query, getDocs, getDoc, doc, updateDoc, where
} from 'firebase/firestore'

class Calendar {
    collectionInstance: any;
    constructor(collectionInstance: any) {
        this.collectionInstance = collectionInstance
    }

    /**
        * @description this is similar to the old firestore where query
        * @param  {string} fieldPath the field of the data must compared to
        * @param  {any} opStr op filter
        * @param  {any} value value of the field that needs to be find
        * @returns reference 
    */

    where(fieldPath: string, opStr: any, value: any) {
        return ({
            get: async () => {
                const q = query(this.collectionInstance, where(fieldPath, opStr, value))
                const querySnapshot = await getDocs(q)
                return querySnapshot
            }
        })
    }

    /**
        * @description 
        * @param  {string} docId Document Id
        * @param  {string} collectionId Collection Id
        * @returns update, collection, get 
    */
    doc(docId: string) {
        return ({
            /**
                * @description 
                * @param  {any} data Data to be Updated or Added
                * @returns reference 
            */
            update: async (data: any) => {
                try {
                    const ref = doc(this.collectionInstance, docId)
                    return await updateDoc(ref, data)
                } catch (err) {
                    console.error(err)
                    return
                }
            },
            /**
                * @description 
                * @param  {string} collectionId Collection Id
                * @returns add 
            */
            collection: (collectionId: string) => ({
                add: async (data: any) => {
                    const ref = doc(this.collectionInstance, docId)
                    const coll = collection(ref, collectionId)
                    return addDoc(coll, data)
                }
            }),
            get: async () => {
                const ref = doc(this.collectionInstance, docId)
                const document = await getDoc(ref)
                return document
            }
        })
    }

    async add (data: any) {
        try {
            return addDoc(this.collectionInstance, data)
        } catch (err) {
            console.error(err)
            return
        }
    }
}

export default (collection: any) => {
    return new Calendar(collection)
}