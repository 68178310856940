import React from 'react'
import { useTranslation } from 'react-i18next'

const LoadingScreen = (props) => {
    const { t } = useTranslation()

    return (
        <section className="hero is-fullheight has-text-centered has-background-success" style={{ width: '100%' }}>
            <div className="hero-body">
                <div className="container p-0 has-text-centered">
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/public-contents.appspot.com/o/SHYFTBASE%2FShyftBase-Logo-A.png?alt=media&token=1d864aae-feaf-4564-a00d-b9af22807695"
                        alt="Shyftbase logo"
                        width={250}
                    />

                    <p className="title has-text-white goldstruckWithDelay">{t('loadingMessage')}</p>
                    <p className="subtitle has-text-white is-6">{t('loadingMessage2')}</p>
                    <div className="loading-dots">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                    </div>
                </div>
            </div>
            <style>{`
                .loading-dots {
                    display: flex;
                    justify-content: center;
                    margin-top: 10px;
                }
                .dot {
                    width: 10px;
                    height: 10px;
                    margin: 0 5px;
                    background-color: white;
                    border-radius: 50%;
                    animation: blink 1.4s infinite both;
                }
                .dot:nth-child(1) { animation-delay: 0s; }
                .dot:nth-child(2) { animation-delay: 0.2s; }
                .dot:nth-child(3) { animation-delay: 0.4s; }
                @keyframes blink {
                    0% { opacity: 0.2; }
                    20% { opacity: 1; }
                    100% { opacity: 0.2; }
                }
            `}</style>
        </section>
    )
}

export default React.memo(LoadingScreen)
