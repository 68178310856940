import {
    collection, addDoc, query, getDoc, doc, updateDoc, where
} from 'firebase/firestore'

class OrderCount {
    collectionInstance: any;
    constructor(collectionInstance: any) {
        this.collectionInstance = collectionInstance
    }

    doc(docId: string) {
        return ({
            collection: async (collectionId: string) => ({
                doc: async(docInnerId: string) => {
                    const ref = doc(this.collectionInstance, docId)
                    const coll = collection(ref, collectionId)
                    const innerRef = doc(coll, docInnerId)
                    return innerRef
                }
            })
        })
    }
}


export default (collection: any) => {
    return new OrderCount(collection)
}