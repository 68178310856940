export const checkHasAccessFunction = (callerMethod, actions) => {
    if (!actions) return false

    switch (callerMethod) {
        case 'handleOrderBillingState':
            return actions['HANDLE ORDER BILLING STATE']
        case 'handleOrderSettlementState':
            return actions['HANDLE ORDER SETTLEMENT STATE']
        case 'handleBothState':
        case 'handlerBillingStateBoth':
            return actions['HANDLE ORDER BILLING STATE'] && actions['HANDLE ORDER SETTLEMENT STATE']
        case 'unlockOrder':
            return actions['UNCONFIRM & UNLOCK ORDER']
        case 'lockOrderAccounting':
            return actions['LOCK ORDER - ACCOUNTING']
        case 'unlockVehicle':
            return actions['UNLOCK OPTIMIZED VEHICLES']
        case 'editOrderFromOrderDisplay':
            return actions['EDIT ORDER DETAILS']

        case 'handleToggleDraftStateForExports':
            return actions['TOGGLE DRAFT STATE FOR EXPORTS']
        case 'generateInvoices':
            return actions['GENERATE INVOICES']
        case 'generateSettlements':
            return actions['GENERATE SETTLEMENTS']
        case 'exportOrders':
            return actions['GENERATE INVOICES'] || actions['GENERATE SETTLEMENTS']

        case 'createCommingeldOrder':
            return actions['CREATE ORDER']
        case 'createDedicatedOrder':
            return actions['CREATE DEDICATED ORDER']
        case 'manageInventory':
            return actions['CREATE INVENTORY ITEM']
        case 'manageReverseLogistics':
            return actions['CREATE REVERSE LOGISTICS ORDER']
        case 'calculateStemMinPay':
            return actions['CALCULATE STEM MIN PAY']
        case 'fetchFSC':
            return actions['FETCH FSC']

        case 'manageSharedView':
            return actions['UPDATE SHARED VIEW']
        case 'sharePublicView':
            return actions['SHARE PUBLIC VIEW']

        //context menu(right click action) && quickAction
        case 'Prints':
            return actions['PRINT ORDERS']
        case 'Create BOL':
            return actions['ACCESS BOL']
        case 'pushToDriver':
            return actions['PUSH TO DRIVER']
        case 'Reschedule Order':
            return actions['RESCHEDULE ORDERS']
        case 'Send Text & Robocall - All Clients':
            return actions['SEND TEXT & ROBOCALL - ALL CLIENTS']
        case 'handleGeocoding':
            return actions['RE-GEOCODING']
        case 'Create Return To Store':
            return actions['CREATE RETURN TO STORE']
        case 'Create Store Stop':
            return actions['CREATE STORE STOP']
        case 'Cancel Selected Orders':
            return actions['CANCEL ORDERS']
        case 'Reactivate Orders':
            return actions['REACTIVATE ORDERS']

        //orderEtailsExpandedView-OrderDisplay
        case 'Order Detail':
            return actions['VIEW ORDER DETAILS']
        case 'Product Detail':
            return actions['VIEW PRODUCT DETAILS']
        case 'Driver Updates':
            return actions['VIEW DRIVER UPDATES']
        case 'Invoices':
            return actions['VIEW INVOICES']
        case 'Settlements':
            return actions['VIEW SETTLEMENTS']
        case 'Calculation Breakdown':
            return actions['VIEW CALCULATION BREAKDOWN']

        //QuicActions
        case 'bulkUpdateField':
            return actions['BULK UPDATE FIELDS']

        default:
            return false
    }
}

export const checkHasAccessModule = (path, actions) => {
    if (!actions) return false
    switch (path) {
        case '/':
        case '/mainContainer':
        case '/dateRegion':
        case '/records':
        case '/admin/billing/fsc':
            return true
        // return actions['ACCESS RECORDS'];
        case '/analytics':
            return actions['ACCESS ANALYTICS']
        case '/routes':
            return actions['ACCESS ROUTING']
        case '/assets':
            return actions['ACCESS ASSETS']
        case '/mapFleet':
            return actions['ACCESS NETWORK']
        case '/admin/vendor':
            return actions['ACCESS CLIENTS PROFILE']
        case '/admin/notifications':
            return actions['ACCESS NOTIFICATIONS']
        case '/admin/contractor':
            return actions['ACCESS CONTRACTORS PROFILE']
        case '/admin/reports':
            return actions['ACCESS REPORT']
        case '/admin/role':
            return actions['ACCESS ROLES']
        case '/admin/automations':
            return actions['ACCESS AUTOMATION']
        case '/admin/userRole':
            return actions['ACCESS USER MANAGEMENT']
        case '/admin/calendarSettings':
            return actions['ACCESS CALENDAR SETTINGS']
        case '/admin/fields':
            return actions['ACCESS FIELD SETTINGS']
        case '/admin/billing/substatus':
            return actions['ACCESS ORDER STATUSES']
        case '/admin/billing/ratetable':
            return actions['ACCESS RATE TABLES']
        case '/admin/billing/taxes':
            return actions['ACCESS TAXES']
        case '/admin/billing/fscrates':
            return actions['ACCESS FUEL SURCHARGE RATES']
        case '/admin/billing/weeklyfscrates':
            return actions['ACCESS WEEKLY FSC']
        case '/admin/billing/services':
            return actions['ACCESS SERVICES']
        case '/admin/billing/conditions':
            return actions['ACCESS CONDITIONS']
        case '/admin/billing/tiersEscrow':
            return actions['ACCESS TIER AND ESCROW']
        case '/admin/billing/postalcode':
            return actions['ACCESS POSTAL CODE']
        case '/admin/integrations':
            return actions['ACCESS INTEGRATIONS']
        case '/admin/billing/globalUnits':
            return actions['ACCESS GLOBAL UNITS']
        case '/admin':
            return actions['ACCESS ADMIN']

        case '/sharedview':
        case '/trackorder':
        case '/trackorder/:trackId':
        case '/signup':
        case '/login':
        case '/forgotPassword':
        case '/changePassword':
        case '/help':
            return true //public routes
        default:
            return false
    }
}
