import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { CaretDownIcon, CheckSmallIcon } from '@shopify/polaris-icons'
import { Icon, IconSource } from '@shopify/polaris'
import './Selector.css'

interface Option {
    value: any
    label: string
}

interface Group {
    label: string
    options: Option[]
}

interface SelectorProps {
    options: Option[] | Group[]
    isGrouped?: boolean
    placeholder?: string
    onChange?: (selectedOptions: Option | Option[] | any) => void
    defaultSelected?: Option | Option[]
    isMultiSelect?: boolean
    dropUp?: boolean // New prop to control dropup behavior
}

const Selector: React.FC<SelectorProps> = ({
    options,
    isGrouped = false,
    placeholder = 'Select items...',
    onChange,
    defaultSelected = [],
    isMultiSelect = false,
    dropUp = false, // Default to dropdown
}) => {
    const [selectedOptions, setSelectedOptions] = useState<Option[]>(
        Array.isArray(defaultSelected) ? defaultSelected : defaultSelected ? [defaultSelected] : []
    )
    const [isOpen, setIsOpen] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const containerRef = useRef<HTMLDivElement>(null)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const triggerRef = useRef<HTMLButtonElement>(null)

    const allOptions: Option[] = useMemo(
        () => (isGrouped ? (options as Group[]).flatMap((group) => group.options) : (options as Option[])),
        [options, isGrouped]
    )

    useEffect(() => {
        setSelectedOptions(Array.isArray(defaultSelected) ? defaultSelected : defaultSelected ? [defaultSelected] : [])
    }, [defaultSelected])

    const toggleOption = (option: Option) => {
        setSelectedOptions((prev) => {
            let newSelection: Option[]
            if (!isMultiSelect) {
                newSelection = [option]
            } else {
                newSelection = prev.some(
                    (item) => (item.value?.id || item.value) === (option.value?.id || option.value)
                )
                    ? prev.filter((item) => (item.value?.id || item.value) !== (option.value?.id || option.value))
                    : [...prev, option]
            }

            if (onChange) {
                onChange(!isMultiSelect ? newSelection[0] : newSelection)
            }
            return newSelection
        })
        if (!isMultiSelect) {
            setIsOpen(false)
        }
    }

    const getFilteredOptions = useCallback(() => {
        if (isGrouped) {
            return (options as Group[]).flatMap((group) =>
                group.options.filter(
                    (option) =>
                        option?.label?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
                        group?.label?.toLowerCase()?.includes(searchTerm?.toLowerCase())
                )
            )
        } else {
            return allOptions.filter((option) => option?.label?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
        }
    }, [options, isGrouped, allOptions, searchTerm])

    const selectAll = () => {
        if (!isMultiSelect) return
        const optionsToSelect = searchTerm ? getFilteredOptions() : allOptions

        const uniqueOptionsMap = new Map(selectedOptions.map((option) => [option.value?.id || option.value, option]))

        optionsToSelect.forEach((option) => {
            const key = option.value?.id || option.value
            uniqueOptionsMap.set(key, option)
        })

        const newSelection = Array.from(uniqueOptionsMap.values())

        setSelectedOptions(newSelection)
        if (onChange) {
            onChange(newSelection)
        }
    }

    const deselectAll = () => {
        if (!isMultiSelect) return
        if (searchTerm) {
            const filteredOptionsToDeselect = getFilteredOptions()
            const newSelection = selectedOptions.filter(
                (option) =>
                    !filteredOptionsToDeselect.some(
                        (filteredOption) =>
                            (filteredOption.value?.id || filteredOption.value) === (option.value?.id || option.value)
                    )
            )
            setSelectedOptions(newSelection)
            if (onChange) {
                onChange(newSelection)
            }
        } else {
            setSelectedOptions([])
            if (onChange) {
                onChange([])
            }
        }
    }

    const filteredOptions = useMemo(() => {
        if (isGrouped) {
            return (options as Group[])
                .map((group) => ({
                    ...group,
                    options: group.options.filter(
                        (option) =>
                            option?.label?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
                            group?.label?.toLowerCase()?.includes(searchTerm?.toLowerCase())
                    ),
                }))
                .filter((group) => group.options.length > 0)
        } else {
            return allOptions.filter((option) => option?.label?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
        }
    }, [options, isGrouped, allOptions, searchTerm])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        if (isOpen) {
            const dropdownContent = dropdownRef.current
            const triggerElement = triggerRef.current

            if (dropdownContent && triggerElement) {
                const triggerRect = triggerElement.getBoundingClientRect()
                const dropdownRect = dropdownContent.getBoundingClientRect()

                const topSpace = triggerRect.top
                const bottomSpace = window.innerHeight - triggerRect.bottom

                if (dropUp || (topSpace > bottomSpace && dropdownRect.height > bottomSpace)) {
                    dropdownContent.style.bottom = `${triggerRect.height}px`
                    dropdownContent.style.top = 'auto'
                    dropdownContent.style.maxHeight = `${topSpace - 10}px` // 10px buffer
                } else {
                    dropdownContent.style.top = `${triggerRect.height}px`
                    dropdownContent.style.bottom = 'auto'
                    dropdownContent.style.maxHeight = `${bottomSpace - 10}px` // 10px buffer
                }
            }
        }
    }, [isOpen, dropUp])

    const renderOptions = () => {
        if (isGrouped) {
            return (filteredOptions as Group[]).map((group) => (
                <div key={group.label}>
                    <div className="dropdown-item">
                        <p className="has-text-weight-bold">{group.label}</p>
                    </div>
                    {group.options.map((option) => renderOption(option))}
                </div>
            ))
        } else {
            return (filteredOptions as Option[]).map((option) => renderOption(option))
        }
    }

    const renderOption = (option: Option) => (
        <a
            key={option.value?.id || option.value}
            href="#"
            className="dropdown-item is-size-7"
            onClick={(e) => {
                e.preventDefault()
                toggleOption(option)
            }}
        >
            <span className="icon-text">
                <span className="icon">
                    {selectedOptions.some(
                        (item) => (item.value?.id || item.value) === (option.value?.id || option.value)
                    ) ? (
                        <Icon source={CheckSmallIcon as IconSource} />
                    ) : (
                        <span className="checkbox-placeholder">☐</span>
                    )}
                </span>
                <span>{option.label}</span>
            </span>
        </a>
    )

    return (
        <div className="multi-select-container" ref={containerRef}>
            <div className={`dropdown ${isOpen ? 'is-active' : ''}`}>
                <div className="dropdown-trigger">
                    <button
                        ref={triggerRef}
                        style={{ borderRadius: 10 }}
                        className="button is-small"
                        aria-haspopup="true"
                        aria-controls="dropdown-menu"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <span className="is-size-7">
                            {selectedOptions.length === 0
                                ? placeholder
                                : !isMultiSelect
                                ? selectedOptions[0].label
                                : selectedOptions.length === allOptions.length
                                ? `All selected (${selectedOptions.length})`
                                : `${selectedOptions.length} selected`}
                        </span>
                        <span className="icon is-small">
                            <Icon source={CaretDownIcon as IconSource} />
                        </span>
                    </button>
                </div>
                {isOpen && (
                    <div className="dropdown-menu" id="dropdown-menu" role="menu" ref={dropdownRef}>
                        <div className="dropdown-content">
                            <div className="dropdown-item">
                                <input
                                    className="input is-size-7 search-input"
                                    type="text"
                                    placeholder="Search items..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <div className="options-container">{renderOptions()}</div>
                            {!!isMultiSelect && (
                                <>
                                    <hr className="dropdown-divider" />
                                    <div className="dropdown-item">
                                        <div className="buttons-container">
                                            <button className="button is-small is-rounded " onClick={deselectAll}>
                                                Deselect All
                                            </button>
                                            <button
                                                className="button is-small is-rounded is-success"
                                                onClick={selectAll}
                                            >
                                                Select All
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
            {!!isMultiSelect && (
                <div className="tags-container">
                    {selectedOptions.map((option) => (
                        <span key={option.value?.id || option.value} className="tag is-light">
                            {option.label?.length > 35 ? `${option.label.substring(0, 35)}...` : option.label}
                            {isGrouped &&
                                ` (${
                                    (options as Group[]).find((g) =>
                                        g.options.some(
                                            (o) => (o.value?.id || o.value) === (option.value?.id || option.value)
                                        )
                                    )?.label
                                })`}
                        </span>
                    ))}
                </div>
            )}
        </div>
    )
}

export default React.memo(Selector)
