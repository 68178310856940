import { Icon, IconSource } from '@shopify/polaris'
import { SettingsIcon } from '@shopify/polaris-icons'
import React, { useCallback, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { checkHasAccessModule } from '../../../../../helpers/checkHasAccess'
import { current } from '@reduxjs/toolkit'

const BillingModuleDropdown = () => {
    const location = useLocation()
    const [showDropdown, setShowDropdown] = useState(false)
    const actions = useSelector((state: any) => state.user.actions)

    const currentSettings = {
        '/admin/billing/ratetable': 'Rate Table',
        '/admin/billing/zones': 'Zones',
        '/admin/billing/fsc': 'Fuel Surcharge',
        '/admin/billing/services': 'Services',
        '/admin/billing/substatus': 'Statuses',
        '/admin/billing/conditions': 'Conditions',
        '/admin/billing/tiersEscrow': 'Tiers & Escrow',
        '/admin/billing/globalUnits': 'Global Units',
        '/admin/billing/postalcode': 'Postal Code',
        '/admin': 'Main Admin Settings',
    }

    const currentSettingsIcon = {
        '/admin/billing/ratetable': SettingsIcon,
        '/admin/billing/zones': SettingsIcon,
        '/admin/billing/fscrates': SettingsIcon,
        '/admin/billing/weeklyfscrates': SettingsIcon,
        '/admin/billing/services': SettingsIcon,
        '/admin/billing/substatus': SettingsIcon,
        '/admin/billing/conditions': SettingsIcon,
        '/admin/billing/tiersEscrow': SettingsIcon,
        '/admin/billing/postalcode': SettingsIcon,
        '/admin': SettingsIcon,
        '/admin/billing/fsc': SettingsIcon,
        '/admin/billing/globalUnits': SettingsIcon,
    }

    const toggleDropdown = useCallback(() => {
        setShowDropdown(!showDropdown)
    }, [showDropdown])

    return (
        <div className={`dropdown ${showDropdown ? 'is-active' : ''}`}>
            <div className="dropdown-trigger">
                <button
                    className="button shyftbaseSettings_button is-small"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                    onClick={toggleDropdown}
                    style={{
                        border: 'none',
                    }}
                >
                    <span className="is-size-7">{currentSettings[location.pathname]}</span>
                    <span className="icon is-small">
                        <i className="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                </button>
            </div>
            <div className="dropdown-menu shyftbaseSettings_dropdown" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                    {Object.keys(currentSettings)
                        .filter((key) => checkHasAccessModule(key, actions))
                        .map((key, index) => {
                            return (
                                <a
                                    href={key}
                                    className={`dropdown-item ${location.pathname === key ? 'is-active' : ''}`}
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        pointerEvents: location.pathname === key ? 'none' : 'auto',
                                        cursor: location.pathname === key ? 'default' : 'pointer',
                                    }}
                                >
                                    <span className="icon is-small shyftbase_icon mr-2">
                                        <Icon source={currentSettingsIcon[key] as IconSource} />
                                    </span>
                                    <span>{currentSettings[key]}</span>
                                </a>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}

export default React.memo(BillingModuleDropdown)
