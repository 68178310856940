import axios from 'axios'
import { getIdToken, onAuthStateChanged, signOut } from 'firebase/auth'
import React, { Suspense, lazy, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useSnackbar } from 'react-simple-snackbar'
import Nav from '../components/Nav/Nav'
import { checkHasAccessModule } from '../helpers/checkHasAccess'
import SignUp from '../pages/SignUp'
import { auth, rolesCollection } from '../services/firebase/firebaseConfig'
import styles from './mainRoutes.styles.module.css'

import { GlobalContext } from '../core/context/globalContext'
import { setGuidesStepIndex, fetchBillingData, fetchData, fetchDispatchData } from './redux/store'
import {
    fetchUserData,
    updateGuideWalkthrough,
    initUserData,
    resetUserData,
    setUserDataLoaded,
    updateActions,
} from './redux/store/userSlice/userSlice'
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride'

import LoadingScreen from '../components/LoadingScreen/LoadingScreen'
import GuideWelcome from '../components/Guides/GuideWelcome'
import PostalCode from '../pages/Admin/view/components/BillingAdmin/AdminTab/PostalCode/PostalCode'

const Admin = lazy(() => import('../pages/Admin'))
const Integrations = lazy(() => import('../pages/Integrations'))
const Assets = lazy(() => import('../pages/Assets'))
const AdminRoleSettings = lazy(() => import('../pages/Admin/view/RoleSettings'))
const AdminUserRoleSettings = lazy(() => import('../pages/Admin/view/UserRoleSettings'))
const ChangePassword = lazy(() => import('../pages/DateRegion/changePassword'))
const Conditions = lazy(() => import('../pages/Admin/view/components/BillingAdmin/AdminTab/Conditions/Conditions'))
const ContractorProfileSettings = lazy(() => import('../pages/Admin/view/ContractorProfileSettings'))
const DateRegion = lazy(() => import('../pages/DateRegion'))
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'))
const FSCRates = lazy(() => import('../pages/Admin/view/components/BillingAdmin/AdminTab/FSCRates/FSCRates'))
const IkeaPostalCode = lazy(
    () => import('../pages/Admin/view/components/BillingAdmin/AdminTab/IkeaPostalCode/IkeaPostalCode')
)
const Login = lazy(() => import('../pages/Login'))
const NotificationSettings = lazy(() => import('../pages/Admin/view/NotificationSettings'))
const MapFleet = lazy(() => import('../pages/MapFleet'))
const OrderTable = lazy(() => import('../pages/OrderTable'))
const RateTableSettings = lazy(() => import('../pages/Admin/view/components/BillingAdmin/RateTableSettings'))
const Reports = lazy(() => import('../pages/Reports'))
const Services = lazy(() => import('../pages/Admin/view/components/BillingAdmin/AdminTab/Services/Services'))
const SubStatus = lazy(() => import('../pages/Admin/view/components/BillingAdmin/AdminTab/SubStatus/SubStatus'))
const TiersEscrow = lazy(() => import('../pages/Admin/view/components/BillingAdmin/AdminTab/TiersEscrow'))
const TrackOrder = lazy(() => import('../pages/TrackOrder'))
const VenderProfileSettings = lazy(() => import('../pages/Admin/view/VendorProfileSettings'))
const WeeklyFSCRates = lazy(
    () => import('../pages/Admin/view/components/BillingAdmin/AdminTab/WeeklyFSCRates/WeeklyFSCRates')
)
const Taxes = lazy(() => import('../pages/Admin/view/components/BillingAdmin/AdminTab/Taxes/Taxes'))
const CalendarSettings = lazy(() => import('../pages/Admin/view/CalendarSettings'))
const ThresholdsSettings = lazy(() => import('../pages/Admin/view/ThresholdsSettings'))
const SharedView = lazy(() => import('../pages/SharedView'))
const Help = lazy(() => import('../pages/Help'))
const GlobalUnits = lazy(() => import('../pages/Admin/view/components/BillingAdmin/AdminTab/GlobalUnits/GlobalUnits'))

const FSC = lazy(() => import('../pages/Admin/view/components/BillingAdmin/AdminTab/FSC/FSC'))
// const fetchWorker = new Worker(new URL('../fetchWorker.ts', import.meta.url));

const MainRoutes = () => {
    const [run, setRun] = useState(false)
    const [steps, setSteps] = useState<any[]>([])
    const {
        user: { updateUser, user, displayName },
    } = useContext(GlobalContext)
    const dispatch = useDispatch()
    const allPreFetchDataLoaded = useSelector(
        (state: any) =>
            state.data.loaded && state.billingData.billingDataloaded && state.dispatchData.dispatchDataLoaded
    )
    const { actions, role, userDataLoaded, completedGuidesWalkthrough } = useSelector((state: any) => state.user)
    const { guidesStepIndex } = useSelector((state: any) => state.data)

    const location = useLocation()
    const history = useHistory()
    const [openSnackbar] = useSnackbar()
    const { t } = useTranslation()

    const { trackId, rateId, viewId } = useParams<any>()

    useEffect(() => {
        const _steps: any[] = [
            {
                target: '.guide-start',
                content: <GuideWelcome />,
                route: '/dateRegion',
                disableBeacon: true, // This causes it to start automatically.
            },
        ]
        if (checkHasAccessModule('/dateRegion', actions)) {
            _steps.push(
                {
                    target: '.guide-dateRegion',
                    content: 'You can query orders and inventory items from this menu.',
                    route: '/dateRegion',
                },
                {
                    target: '.guide-creationFlow',
                    content: 'Create orders manually or import them using a file.',
                    route: '/dateRegion',
                },
                {
                    target: '.guide-ViewToolPanel',
                    content: 'Utilize views for predefined filters and queries.',
                    route: '/dateRegion',
                }
            )
        }
        if (checkHasAccessModule('/mapFleet', actions)) {
            _steps.push({
                target: '.guide-network',
                content:
                    'Manage your entire network, fleet or plan your routes. Create your hubs, zones, stores and links between them.',
                route: '/mapFleet',
            })
        }
        if (checkHasAccessModule(location.pathname, actions)) {
            _steps.push({
                target: '.guide-admin',
                content: 'Set up all your business logic here, in the admin module.',
                route: '/admin',
            })
            _steps.push({
                target: '.guide-setup-business',
                content: 'Start here. We will guide you through the setup process.',
                route: '/admin',
            })
            _steps.push({
                target: '.guide-setup-billingmodule',
                content: 'Set up all your rate cards, and all the related logic here.',
                route: '/admin',
            })
        }
        setSteps(_steps)
    }, [actions])

    const handleJoyrideCallback = useCallback(
        async (data: CallBackProps) => {
            const { action, index, status, type } = data
            const nextStep = steps[index + 1]

            // Your callback logic, updating currentStepIndex as needed
            if (type === EVENTS.STEP_AFTER && action === ACTIONS.NEXT) {
                dispatch(setGuidesStepIndex(index + 1))
            } else if (type === EVENTS.STEP_AFTER && action === ACTIONS.PREV) {
                dispatch(setGuidesStepIndex(Math.max(index - 1, 0)))
            }

            if (
                status === STATUS.FINISHED &&
                index === steps.length - 1 &&
                action !== ACTIONS.CLOSE &&
                action !== ACTIONS.UPDATE
            ) {
                setRun(false) // End the tour if finished, skipped, or closed
                await dispatch(updateGuideWalkthrough(true))
            } else if (type === EVENTS.STEP_AFTER && nextStep && nextStep.route) {
                // If there is a next step, and it has a route, navigate to it
                setTimeout(() => history.push(nextStep.route), 0) // Adding a slight delay
            } else if (action === ACTIONS.PREV && index > 0 && steps[index - 1].route) {
                // If the PREVIOUS step has a route, navigate to it
                history.goBack()
            }
        },
        [steps, history, dispatch, updateGuideWalkthrough]
    )

    useEffect(() => {
        let timeOut
        // (async () => {
        //     if (window.Worker) {
        //         if (!loaded) {
        //             fetchWorker.postMessage({ action: 'appPreRequisites' });
        //             fetchWorker.onmessage = (event: MessageEvent) => {
        //                 if (event.data.error) {
        //                     console.log("event.data.error", event.data.error)
        //                 } else {
        //                     if (event?.data?.data) dispatch(updateDataState(event?.data?.data))
        //                     if (event?.data?.dispatchData) dispatch(updateDispatchState(event?.data?.dispatchData))
        //                     console.log("dispatching updateDataState & updateDispatchState", event?.data)
        //                 }
        //             };
        //             fetchWorker.onerror = (event: ErrorEvent) => {
        //                 console.log("event.message", event.message)
        //             };
        //         }
        //     } else {
        //         const fetchPreRequisites = async () => {
        //             await dispatch(fetchUserData());
        //             await dispatch(fetchDispatchData());
        //         };
        //         fetchPreRequisites();
        //     }
        // })();
        try {
            onAuthStateChanged(auth, async (user) => {
                try {
                    if (user) {
                        if (location.pathname === '/login' || location.pathname === '/') {
                            history.push('/dateRegion')
                            return
                        }

                        dispatch(setUserDataLoaded(false))

                        const idToken = await getIdToken(user, true)
                        const validateLink = process.env.REACT_APP_VALIDATE_EMAIL || ''
                        /*
                        {
                            role,
                            validAccess,
                            driverProfileDetails,
                            clients,
                        }
                        */
                        const validationResult = await axios({
                            url: validateLink,
                            method: 'POST',
                            data: {
                                email: user.email,
                                token: idToken,
                                customerId: process.env.REACT_APP_CLIENT,
                                component: 'PORTAL',
                            },
                        })
                        /* fetch vendor and driver profiles from the IDs that come in for the user */
                        /* fetch role from the ID for the user */
                        if (!validationResult) {
                            await signOut(auth)
                            dispatch(resetUserData())
                            return
                        }
                        if (
                            validationResult.data &&
                            validationResult.data.validAccess === 'VALID_EMAIL' &&
                            validationResult.data.active
                        ) {
                            const userPayload = {
                                email: user.email,
                                firstName: validationResult.data.firstName,
                                role: validationResult.data.role,
                                isAllDistributionZones: validationResult.data.isAllDistributionZones,
                                userDistributionZoneIds: validationResult.data.distributionZones,
                                isAllClients: validationResult.data.isAllClients,
                                userClientIds: validationResult.data.clients,
                                id: validationResult.data.id,
                                completedGuidesWalkthrough: validationResult.data.completedGuidesWalkthrough,
                            }
                            /* global context , to later be replaced entirely by redux */
                            updateUser(user)

                            /* redux update */
                            dispatch(initUserData(userPayload))
                            /* capturing user ctions */
                            const docRef = await rolesCollection.getDocById(userPayload.role.id)
                            const actionsData = docRef?.data()?.actions || {}
                            dispatch(updateActions(actionsData))
                            /* fetch authorized pre requisites based on user role */
                            dispatch(fetchUserData(userPayload.role.id))

                            if (!allPreFetchDataLoaded) {
                                Promise.all([
                                    dispatch(fetchBillingData()),
                                    dispatch(fetchData({ roleId: userPayload.role.id })),
                                    dispatch(fetchDispatchData()),
                                ])
                            }
                            /* steps for the guide joy */
                            setTimeout(() => setRun(true), 1000)
                        } else if (validationResult.data && validationResult.data.validAccess === 'INVALID_EMAIL') {
                            openSnackbar(`User is not valid, please contact Admin`)
                            dispatch(resetUserData())
                            await signOut(auth)
                            return
                        } else {
                            openSnackbar(`Invalid Account`)
                            dispatch(resetUserData())
                            await signOut(auth)
                            return
                        }
                    } else {
                        dispatch(resetUserData())
                        loadPublicModule(location.pathname || '/login')
                    }
                } catch (error) {
                    dispatch(resetUserData())
                    loadPublicModule(location.pathname || '/login')
                }
            })
        } catch (error) {
            console.log(error)
            dispatch(resetUserData())
            return
        }

        return () => {
            clearTimeout(timeOut)
            // fetchWorker.terminate();
        }
    }, [])

    const checkIsPublic = useCallback((pathName: string) => {
        return (
            pathName.includes('forgotPassword') ||
            pathName.includes('trackorder') ||
            pathName.includes('sharedview') ||
            pathName.includes('help') ||
            pathName.includes('signup') ||
            pathName.includes('login')
        )
    }, [])

    const loadPublicModule = useCallback((pathName: string) => {
        if (checkIsPublic(pathName)) {
            history.push(pathName)
        } else {
            history.push('/login')
        }
    }, [])

    const loadModule = useCallback(
        (pathName: string) => {
            const path = `/${pathName.split('/')[1]}`

            if (!checkHasAccessModule(path, actions)) {
                const hasRecordAccess = checkHasAccessModule('/dateRegion', actions)

                hasRecordAccess
                    ? pathName === '/'
                        ? history.push('/dateRegion')
                        : history.goBack()
                    : history.push('/trackorder')
                return
            }

            switch (pathName) {
                //Public Routes
                case '/sharedview':
                case `/sharedview/${viewId}`:
                    return <SharedView isSharedView={true} sharedViewId={viewId} />
                case '/trackorder':
                    return <TrackOrder trackId={''} />
                case `/trackorder/${trackId}`:
                    return <TrackOrder trackId={trackId} />
                case '/signup':
                    return <SignUp />
                case '/help':
                    return <Help />
                case '/login':
                    return <Login />
                case '/forgotPassword':
                    return <ForgotPassword />
                case '/changePassword':
                    return <ChangePassword />

                //Private Routes
                case '/mainContainer':
                case '/dateRegion':
                    return <DateRegion />
                case '/analytics':
                    return <Reports />
                case '/records':
                    return <OrderTable />
                case '/mapFleet':
                    return <MapFleet />
                case '/admin':
                    return <Admin />
                case '/assets':
                    return <Assets />
                case '/admin/role':
                    return <AdminRoleSettings />
                case '/admin/userRole':
                    return <AdminUserRoleSettings />
                case '/admin/vendor':
                    return <VenderProfileSettings />
                case '/admin/contractor':
                    return <ContractorProfileSettings />
                case '/admin/notifications':
                    return <NotificationSettings />
                case '/admin/automations':
                    return <ThresholdsSettings />
                case '/admin/calendarSettings':
                    return <CalendarSettings />
                case '/admin/billing/substatus':
                    return <SubStatus />
                case `/admin/billing/ratetable`:
                    return <RateTableSettings id={''} />
                case `/admin/billing/ratetable/${rateId}`:
                    return <RateTableSettings id={rateId} />
                case '/admin/billing/taxes':
                    return <Taxes />
                case '/admin/billing/fscrates':
                    return <FSCRates />
                case '/admin/billing/weeklyfscrates':
                    return <WeeklyFSCRates />
                case '/admin/billing/services':
                    return <Services />
                case '/admin/billing/conditions':
                    return <Conditions />
                case '/admin/billing/tiersEscrow':
                    return <TiersEscrow />
                case '/admin/billing/postalcode':
                    return <PostalCode />
                case '/admin/billing/globalUnits':
                    return <GlobalUnits />
                case '/admin/billing/fsc':
                    return <FSC />
                case '/admin/integrations':
                    return <Integrations />
                default:
                    return <DateRegion />
            }
        },
        [rateId, actions]
    )

    return (
        <div className={styles.app}>
            {!role && checkIsPublic(location.pathname) ? (
                <div className={'shyftbase_main_layout_public'}>
                    <div className="shyftbase_main_layout_content">
                        <div style={{ overflow: 'hidden' }}>
                            <>
                                <Suspense fallback={<LoadingScreen />}>{loadModule(location.pathname || '/')}</Suspense>
                            </>
                        </div>
                    </div>
                </div>
            ) : !(allPreFetchDataLoaded && userDataLoaded) ? (
                <LoadingScreen />
            ) : (
                <div className={'shyftbase_main_layout'}>
                    <div>{<Nav />}</div>
                    <div className="shyftbase_main_layout_content">
                        <div style={{ overflow: 'hidden' }}>
                            <>
                                <Suspense fallback={<LoadingScreen />}>{loadModule(location.pathname || '/')}</Suspense>
                            </>
                        </div>
                    </div>
                </div>
            )}
            {user && !completedGuidesWalkthrough && (
                <Joyride
                    continuous
                    disableCloseOnEsc
                    disableOverlayClose
                    run={run}
                    steps={steps}
                    stepIndex={guidesStepIndex} // Control the current step with the index from context
                    callback={handleJoyrideCallback}
                    scrollToFirstStep={true}
                    showProgress={true}
                    showSkipButton={true}
                    styles={{
                        options: {
                            width: 500,
                            zIndex: 1000,
                            primaryColor: '#000',
                        },
                    }}
                />
            )}
        </div>
    )
}
export default React.memo(MainRoutes)
