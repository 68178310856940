import {
    ADD_ORDERS, DELETE_ORDERS, EMPTY_CHAT_HISTORY, EMPTY_ORDERS, PUSH_MESSAGE, SELECT_ORDER, UPDATE_ORDERS
} from './orderAction.types'
import {
    approveOrder, requestInfo, cancelOrder, updateOrder, ordersCollection
} from '../../../../services/firebase/firebaseConfig'

export const OrderAction = {
    addOrders: (dispatch: any, value: any) => {
        dispatch({
            type: ADD_ORDERS,
            payload: value
        })
    },
    updateOrders: (dispatch: any, value: any) => {
        dispatch({
            type: UPDATE_ORDERS,
            payload: value
        })
    },
    deleteOrders: (dispatch: any, value: any) => {
        dispatch({
            type: DELETE_ORDERS,
            payload: value
        })
    },
    approveOrder: async (dispatch: any, value: any) => {
        await approveOrder({ order: value })
    },
    requestInfo: async (dispatch: any, value: any) => {
        await requestInfo({ ...value })
    },
    cancelOrder: async (dispatch: any, value: any) => {
        await cancelOrder({  order: value })
    },
    updateOrder: async (dispatch: any, value: any) => {
        await updateOrder({  order: value })
    },
    updateNewMessageStatus: async (dispatch: any, value: any) => {
        const { docId, newMessageStatus } = value
        await ordersCollection.doc(docId).update(newMessageStatus)
    },
    addNewMessage: async (dispatch: any, value: any) => {
        const { docId, data } = value
        const chatRef = ordersCollection.doc(docId).collection('CHAT')
        await chatRef.add(data)
    },
    emptyOrders: (dispatch: any) => {
        dispatch({
            type: EMPTY_ORDERS
        })
    },
    selectOrder:(dispatch: any, value: any) => {
        dispatch({
            type: SELECT_ORDER,
            payload: value
        })
    },
    emptyChatHistory: (dispatch: any) => {
        dispatch({
            type: EMPTY_CHAT_HISTORY
        })
    },
    pushMessage: (dispatch: any, value: any) => {
        dispatch({
            type: PUSH_MESSAGE,
            payload: value
        })
    },
}
