import {
    SELECT_TABLE_ORDER,
    SELECT_TABLE_SELECTED_DATE,
    SELECT_TABLE_SELECTED_CLIENT,
    SELECT_TABLE_SELECTED_REGION,
    UPDATE_RATE_TABLES,
    UPDATE_STEMS,
    UPDATE_ACTIONS,
    UPDATE_CONTRACTORS,
    UPDATE_RATE_CONDITIONS,
    UPDATE_ROLE,
    SET_SELECTED_VIEW,
    ADD_COUNT_FOR_FETCH
} from './tableAction.types'

export const TableReducer = (state: any, action: any) => {
    switch (action.type) {
        case SET_SELECTED_VIEW:
            return {
                ...state,
                selectedView: action.payload
            }
        case UPDATE_RATE_CONDITIONS:
            return {
                ...state,
                rateConditions: action.payload,
            }
        case UPDATE_RATE_TABLES:
            return {
                ...state,
                rateTables: action.payload,
            }
        case SELECT_TABLE_ORDER:
            return {
                ...state,
                selectedTableOrder: action.payload,
            }
        case SELECT_TABLE_SELECTED_DATE:
            return {
                ...state,
                selectedDate: action.payload,
            }
        case SELECT_TABLE_SELECTED_REGION:
            return {
                ...state,
                selectedRegion: action.payload,
            }
        case SELECT_TABLE_SELECTED_CLIENT:
            return {
                ...state,
                selectedClient: action.payload,
            }
        case UPDATE_STEMS:
            return {
                ...state,
                allStems: action.payload
            }
        case UPDATE_ACTIONS:
            return {
                ...state,
                actions: action.payload,
            }
        case UPDATE_ROLE:
            return {
                ...state,
                role: action.payload,
            }
        case UPDATE_CONTRACTORS:
                return {
                        ...state,
                        contractorsList: action.payload,
                    }
        case ADD_COUNT_FOR_FETCH:
            return {
                ...state,
                countForFetch: state.countForFetch + 1
            }

        default:
            return state
    }
}