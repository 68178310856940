import axios from 'axios'

export const ReportsAction = {
    generateCSV: (value: any) => {
        return new Promise((resolve, reject) => {
            axios({
                // responseType: 'blob',
                url: process.env.REACT_APP_GENERATE_REPORTS_FROM_BQ_URL,
                method: 'POST',
                data: value,
            }).then((response) => {
                const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                const fileLink = document.createElement('a')

                fileLink.href = fileURL
                fileLink.setAttribute('download', 'bestbuy_survey_report.csv')
                document.body.appendChild(fileLink)

                fileLink.click()
                return resolve('successfully generated CSV')
            }).catch(e => {
                return reject(e && e.response && e.response.data)
            })
        })
    },
    generateCSVFromLive: (value: any) => {
        return new Promise((resolve, reject) => {
            axios({
                // responseType: 'blob',
                url: process.env.REACT_APP_GENERATE_REPORTS_FROM_LIVE_URL,
                method: 'POST',
                data: value,
            }).then((response) => {
                const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                const fileLink = document.createElement('a')

                fileLink.href = fileURL
                fileLink.setAttribute('download', 'bb_survey_report_live.csv')
                document.body.appendChild(fileLink)

                fileLink.click()
                return resolve('successfully generated CSV')
            }).catch(e => {
                return reject(e && e.response && e.response.data)
            })
        })
    },
    generateCSVForDataExtract: (value: any) => {
        return new Promise((resolve, reject) => {
            axios({
                // responseType: 'blob',
                url: process.env.REACT_APP_GENERATE_REPORTS_FROM_BQ_URL,
                method: 'POST',
                data: value,
            }).then((response) => {
                const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                const fileLink = document.createElement('a')

                fileLink.href = fileURL
                fileLink.setAttribute('download', 'bestbuy_data_extract.csv')
                document.body.appendChild(fileLink)

                fileLink.click()
                return resolve('successfully generated CSV')
            }).catch(e => {
                return reject(e && e.response && e.response.data)
            })
        })
    },
    generateCSVLiveWithComments: (value: any) => {
        return new Promise((resolve, reject) => {
            axios({
                // responseType: 'blob',
                url: process.env.REACT_APP_GENERATE_REPORTS_FROM_LIVE_WITH_COMMENTS,
                method: 'POST',
                data: value,
            }).then((response) => {
                const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                const fileLink = document.createElement('a')

                fileLink.href = fileURL
                fileLink.setAttribute('download', 'bestbuy_survey_report_with_comments.csv')
                document.body.appendChild(fileLink)

                fileLink.click()
                return resolve('successfully generated CSV')
            }).catch(e => {
                return reject(e && e.response && e.response.data)
            })
        })
    }
}