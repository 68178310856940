import React, { useCallback, useEffect, useState } from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import SnackbarProvider from 'react-simple-snackbar'
import '../i18n/i18n'
import GlobalState from './context/globalState'
import MainRoutes from './mainRoutes'
// const billingWorker = new Worker(new URL('../billingWorker.ts', import.meta.url));
const Routes = () => {

    // const { billingDataloaded } = useSelector((state: any) => state.billingData)

    // useEffect(() => {
    //  setTimeout(() => setRun(true), 5000)
    //         if (window.Worker) {
    //             if (!billingDataloaded) {
    //                 // ********************* Billing Worker ********************* //
    //                 billingWorker.postMessage({ action: 'billingPreRequisites' });
    //                 billingWorker.onmessage = (event: MessageEvent) => {
    //                     if (event.data.error) {
    //                         console.log("billingWorker event.data.error", event.data.error)
    //                     } else {
    //                         dispatch(updateBillingState(event?.data?.billingData))
    //                         console.log("dispatching updateBillingState", event?.data)
    //                     }
    //                 };
    //                 billingWorker.onerror = (event: ErrorEvent) => {
    //                     console.log("billingWorker event.message", event.message)
    //                 };
    //             }
    //         } else {
    // }, [])

    return (
        <GlobalState>
            <Router>
                <Switch>
                    <SnackbarProvider>
                        {/* PUBLIC PAGES */}
                        <Route exact path="/help" component={MainRoutes} />
                        <Route exact path="/trackorder" component={MainRoutes} />
                        <Route exact path="/trackorder/:trackId" component={MainRoutes} />
                        <Route exact path="/sharedview/:viewId" component={MainRoutes} />
                        {/* MAIN PORTAL PAGES */}
                        <Route exact path="/" component={MainRoutes} />
                        <Route exact path="/login" component={MainRoutes} />
                        <Route exact path="/signup" component={MainRoutes} />
                        <Route exact path="/forgotPassword" component={MainRoutes} />
                        <Route exact path="/changePassword" component={MainRoutes} />
                        <Route exact path="/mainContainer" component={MainRoutes} />
                        <Route exact path="/records" component={MainRoutes} />
                        <Route exact path="/dateRegion" component={MainRoutes} />
                        <Route exact path="/mapFleet" component={MainRoutes} />
                        <Route exact path="/routes" component={MainRoutes} />
                        <Route exact path="/analytics" component={MainRoutes} />
                        <Route exact path="/assets" component={MainRoutes} />
                        {/* ADMIN PAGES */}
                        <Route exact path="/admin" component={MainRoutes} />
                        <Route exact path="/admin/role" component={MainRoutes} />
                        <Route exact path="/admin/userRole" component={MainRoutes} />
                        <Route exact path="/admin/vendor" component={MainRoutes} />
                        <Route exact path="/admin/contractor" component={MainRoutes} />
                        <Route exact path="/admin/distributionZone" component={MainRoutes} />
                        <Route exact path="/admin/notifications" component={MainRoutes} />
                        <Route exact path="/admin/calendarSettings" component={MainRoutes} />
                        <Route exact path="/admin/fields" component={MainRoutes} />
                        <Route exact path="/admin/integrations" component={MainRoutes} />
                        <Route exact path="/admin/billing" component={MainRoutes} />
                        <Route exact path="/admin/billing/ratetable" component={MainRoutes} />
                        <Route exact path="/admin/billing/ratetable/:rateId" component={MainRoutes} />
                        <Route exact path="/admin/billing/taxes" component={MainRoutes} />
                        <Route exact path="/admin/billing/fscrates" component={MainRoutes} />
                        <Route exact path="/admin/billing/weeklyfscrates" component={MainRoutes} />
                        <Route exact path="/admin/billing/services" component={MainRoutes} />
                        <Route exact path="/admin/billing/substatus" component={MainRoutes} />
                        <Route exact path="/admin/billing/conditions" component={MainRoutes} />
                        <Route exact path="/admin/billing/tiersEscrow" component={MainRoutes} />
                        <Route exact path="/admin/billing/postalcode" component={MainRoutes} />
                        <Route exact path="/admin/automations" component={MainRoutes} />
                        <Route exact path="/admin/billing/globalUnits" component={MainRoutes} />
                        <Route exact path="/admin/billing/fsc" component={MainRoutes} />
                    </SnackbarProvider>
                </Switch>
            </Router>
        </GlobalState>
    )
}
export default React.memo(Routes)
