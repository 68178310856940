import { configureStore, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
    contractorsCollection,
    fleetCollection,
    getCollection,
    getDocs,
    getOrdersCollection,
    getProjectCollection,
    inventoryFieldsCollection,
    orderBy,
    query,
    rateDedicatedServicesCollection,
    rateServicesCollection,
    rateTableTMSCollection,
    ratezonesCollection,
    substatsCollection,
    tmsConfigurationFieldsCollection,
    where,
    distributionZonesCollectionInstance,
    getCountForCollection,
    vendors,
    assetsCollection,
} from '../../../services/firebase/firebaseConfig'

import userReducer from './userSlice/userSlice'

const initialState = {
    orderstatusesref: null as any,
    lockedstatuses: null as any,
    cancellationreasons: null as any,
    ignoreStemStatuses: null as any,
    orderstatuses: null as any,
    opsstatusesref: null as any,
    fieldsnapshot: [],
    fieldsnapshotAuthorizedForUser: [],
    inventoryFieldsnapshot: [],
    inventoryFieldsnapshotAuthorizedForUser: [],
    assets: [], // Add this line for assets
    ordertypesref: null,
    thresholds: null,
    totalCountOfDistributionzones: null,
    totalCountOfClients: null,
    loaded: false,
    guidesStepIndex: 0,
    isBusinessWizzardOpen: false,
}
const initialBillingState = {
    ratesArray: null,
    serviceList: null,
    dedicatedServiceList: null,
    rateZones: null,
    conditionsList: [] as any,
    billingDataloaded: false,
    globalUnits: null,
}
const initialOrderState = {
    selectedOrder: null,
}
const initialDispatchDataState = {
    drivers: null,
    requestedWindowsFieldList: null,
    driverPushStatusFieldList: null,
    contractorsList: null,
    trucksList: null,
    dispatchDataLoaded: false,
}
export const fetchData: any = createAsyncThunk('data/fetchData', async (arg: { roleId: string }, { getState }) => {
    try {
        const [
            orderstatusesref,
            fieldsnapshot,
            inventoryFieldsnapshot,
            assetsSnapshot ,
            ordertypesref,
            thresholdsRef,
            countOfDistributionzones,
            countOfClients,
        ] = await Promise.all([
            substatsCollection.get(), //TODO REFACTOR: WE NEED THIS FETCHING JUST ONCE
            tmsConfigurationFieldsCollection.get(),
            inventoryFieldsCollection.get(),
            assetsCollection.get(), // Add this line for assets
            getCollection('CONFIGURATIONS/OPTIONS/OrderType').get(),
            getOrdersCollection('CONFIGURATIONS').doc('AUTOMATIONS').get(),
            getCountForCollection(distributionZonesCollectionInstance),
            getCountForCollection(vendors),
        ])
        const lockedstatuses: any[] = []
        const cancellationreasons: any[] = []
        const orderstatuses: any[] = []
        const ignoreStemStatuses: any[] = []
        const opsStatuses: any[] = []

        orderstatusesref?.docs.forEach((doc: any) => {
            if (doc.data().shouldLock) {
                lockedstatuses.push(doc.data().value)
            }
            if (doc.data().isCancellationReason) {
                cancellationreasons.push({
                    label: doc.data().value,
                    value: doc.id,
                })
            }
            if (doc.data().isOrderStatus) {
                orderstatuses.push({
                    label: doc.data().value,
                    value: doc.id,
                })
            }
            if (doc.data().ignoreStem) {
                ignoreStemStatuses.push(doc.data().value)
            }

            if(doc.data().isOpsStatus){
                opsStatuses.push({
                    label: doc.data().value,
                    value: {
                        Id: doc.id,
                        Status: doc.data().value,
                    },
                })
            }
        })

        
        const assets = assetsSnapshot?.docs.map((doc: any) => ({
            id: doc.id,
            ...doc.data()
        }))

        return {
            orderstatusesref: orderstatusesref?.docs
                ?.map((d: any) => ({
                    label: d.data().value || "",
                    value: { id: d.id, ...d.data() },
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
            opsstatusesref: opsStatuses
                .sort((a, b) => a.label.localeCompare(b.label)),
            fieldsnapshot: fieldsnapshot?.docs?.map((d: any) => ({ ...d.data(), id: d.id })),
            fieldsnapshotAuthorizedForUser: fieldsnapshot?.docs
                ?.map((d: any) => ({ ...d.data(), id: d.id }))
                .filter((d: any) => d.Visible?.[arg.roleId]),
            inventoryFieldsnapshot: inventoryFieldsnapshot?.docs?.map((d: any) => ({ ...d.data(), id: d.id })),
            inventoryFieldsnapshotAuthorizedForUser: inventoryFieldsnapshot?.docs
                ?.map((d: any) => ({ ...d.data(), id: d.id }))
                .filter((d: any) => d.Visible?.[arg.roleId]),
            ordertypesref: ordertypesref?.docs
                ?.map((d: any) => ({
                    label: d.data().Name || "",
                    value: { id: d.id, ...d.data() },
                }))
                .sort((a, b) => a.label.localeCompare(b.label)),
            thresholds: thresholdsRef?.data(),
            lockedstatuses,
            cancellationreasons,
            orderstatuses,
            ignoreStemStatuses,
            countOfDistributionzones: countOfDistributionzones,
            countOfClients: countOfClients,
            assets: assets, // Add this line for assets
        }
    } catch (error) {
        console.log('error', error)
    }
})
const dataSlicer = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setBusinessWizzardOpen: (state, action) => {
            state.isBusinessWizzardOpen = action.payload
        },
        setGuidesStepIndex: (state, action) => {
            state.guidesStepIndex = action.payload
        },
        setDataloaded: (state, action) => {
            state.loaded = action.payload
        },
        updateOrderStatusesRef: (state, action) => {            

            const lockedstatuses: any[] = []
            const cancellationreasons: any[] = []
            const orderstatuses: any[] = []
            const ignoreStemStatuses: any[] = []
            const opsStatuses: any[] = []
            const orderStatusesRef: any[] = []
            
            action.payload?.forEach((d: any) => {
                
                const data = d.data
                const id = d.id

                orderStatusesRef.push({
                    label: data.value || "",
                    value: { id: d.id, ...data },
                })

                if (data.shouldLock) {
                    lockedstatuses.push(data.value)
                }
                if (data.isCancellationReason) {
                    cancellationreasons.push({
                        label: data.value,
                        value: id,
                    })
                }
                if (data.isOrderStatus) {
                    orderstatuses.push({
                        label: data.value,
                        value: id,
                    })
                }
                if (data.ignoreStem) {
                    ignoreStemStatuses.push(data.value)
                }

                if(data.isOpsStatus){
                    opsStatuses.push({
                        label: data.value,
                        value: {
                            Id: id,
                            Status: data.value,
                        },
                    })
                }
            })
            
            state.orderstatusesref = orderStatusesRef.sort((a, b) => a.label.localeCompare(b.label))
            state.opsstatusesref = opsStatuses.sort((a, b) => a.label.localeCompare(b.label))
            state.lockedstatuses = lockedstatuses
            state.cancellationreasons = cancellationreasons
            state.orderstatuses = orderstatuses
            state.ignoreStemStatuses = ignoreStemStatuses

        },
        updateFieldsSnapshot: (state, action: any) => {
            const copiedData: any = [...state.fieldsnapshot]

            action.payload?.forEach((d: any) => {
                const index = copiedData.findIndex((x: any) => x.id === d.id)
                copiedData[index] = {
                    ...copiedData[index],
                    Editable: {
                        ...copiedData[index].Editable,
                        ...d.data.Editable,
                    },
                    Visible: {
                        ...copiedData[index].Visible,
                        ...d.data.Visible,
                    },
                }
            })

            state.fieldsnapshot = copiedData
        },
        updateInventoryFieldsnapshot: (state, action: any) => {
            const copiedData: any = [...state.inventoryFieldsnapshot]

            action.payload?.forEach((d: any) => {
                const index = copiedData.findIndex((x: any) => x.id === d.id)
                copiedData[index] = {
                    ...copiedData[index],
                    Editable: {
                        ...copiedData[index].Editable,
                        ...d.data.Editable,
                    },
                    Visible: {
                        ...copiedData[index].Visible,
                        ...d.data.Visible,
                    },
                }
            })

            state.inventoryFieldsnapshot = copiedData
        },
        updateDataState: (state, { payload }) => {
            state.orderstatusesref = payload?.orderstatusesref
            state.opsstatusesref = payload?.opsstatusesref
            state.fieldsnapshot = payload?.fieldsnapshot
            state.fieldsnapshotAuthorizedForUser = payload?.fieldsnapshotAuthorizedForUser
            state.inventoryFieldsnapshot = payload?.inventoryFieldsnapshot
            state.inventoryFieldsnapshotAuthorizedForUser = payload?.inventoryFieldsnapshotAuthorizedForUser
            state.ordertypesref = payload?.ordertypesref
            state.lockedstatuses = payload?.lockedstatuses
            state.cancellationreasons = payload?.cancellationreasons
            state.orderstatuses = payload?.orderstatuses
            state.ignoreStemStatuses = payload?.ignoreStemStatuses
            state.thresholds = payload?.thresholds
            state.assets = payload?.assets // Add this line for assets
            state.loaded = true
        },
    },
    extraReducers: {
        [fetchData.pending]: (state) => {
            state.loaded = false
        },
        [fetchData.fulfilled]: (state, { payload }) => {
            state.orderstatusesref = payload?.orderstatusesref
            state.opsstatusesref = payload?.opsstatusesref
            state.fieldsnapshot = payload?.fieldsnapshot
            state.fieldsnapshotAuthorizedForUser = payload?.fieldsnapshotAuthorizedForUser
            state.inventoryFieldsnapshot = payload?.inventoryFieldsnapshot
            state.inventoryFieldsnapshotAuthorizedForUser = payload?.inventoryFieldsnapshotAuthorizedForUser
            state.ordertypesref = payload?.ordertypesref
            state.lockedstatuses = payload?.lockedstatuses
            state.cancellationreasons = payload?.cancellationreasons
            state.orderstatuses = payload?.orderstatuses
            state.ignoreStemStatuses = payload?.ignoreStemStatuses
            state.thresholds = payload?.thresholds
            state.totalCountOfDistributionzones = payload?.countOfDistributionzones
            state.totalCountOfClients = payload?.countOfClients
            state.assets = payload?.assets 
            state.loaded = true
        },
        [fetchData.rejected]: (state) => {
            state.loaded = false
        },
    },
})

export const fetchBillingData: any = createAsyncThunk('billingData/fetchBillingData', async (thunkAPI) => {
    let ratesArray: any = {}
    const serviceList: any = []
    const dedicatedServiceList: any = []
    let rateZones: any = []
    const conditionList: any = []
    const globalUnits: any = []
    try {
        const [ratesSnapshots, serviceSnapshots, dedicatedSeviceSnapshots, ratezonesSnapshots, conditionSnapthos, globalUnitSnapshot] =
            await Promise.all([
                rateTableTMSCollection.get(),
                rateServicesCollection.get(),
                rateDedicatedServicesCollection.get(),
                ratezonesCollection.get(),
                getOrdersCollection('RATE_TABLES_CONDITIONS').get(),
                getCollection('CONFIGURATIONS/OPTIONS/GlobalUnits').get()
            ])
        //STEMS
        if (ratesSnapshots && ratesSnapshots?.docs?.length > 0) {
            ratesArray = ratesSnapshots?.docs.reduce((acc, curr) => {
                acc[curr.id] = curr?.data()
                return acc
            }, {})
        }
        if (serviceSnapshots && serviceSnapshots?.docs?.length > 0) {
            serviceSnapshots.docs.forEach((d) => serviceList.push(d.data()))
        }

        if (dedicatedSeviceSnapshots && dedicatedSeviceSnapshots?.docs?.length > 0) {
            dedicatedSeviceSnapshots.docs.forEach((d) => dedicatedServiceList.push(d.data()))
        }

        if (ratezonesSnapshots && ratezonesSnapshots?.docs?.length > 0) {
            rateZones = ratezonesSnapshots.docs.map((d: any) => ({...d.data(), id: d.id}))
            //TODO NEED TO SORT FROM FIRESTORE QUERY
            rateZones.sort((a: any, b: any) => (a.key || "").localeCompare(b.key || ""))
        }

        if (conditionSnapthos && conditionSnapthos?.docs?.length > 0) {
            conditionSnapthos.docs.forEach((d) => conditionList.push(d.data()))
        }

        if(globalUnitSnapshot && globalUnitSnapshot?.docs?.length > 0){
            globalUnitSnapshot.docs.forEach((d: any) => {
                
                let dataSourceUnit: any = null

                //default value
                switch(d.id) {
                    case 'Weight':
                        dataSourceUnit = 'kg'
                        break
                    case 'Distance':
                        dataSourceUnit = 'km'
                        break
                    case 'Volume':
                        dataSourceUnit = 'm3'
                        break
                }                
                
                const data: any = {                    
                    ...d.data(),
                    dataSourceUnit: dataSourceUnit
                }
                
                globalUnits.push({
                    id: d.id,
                    data: data
                })
            })
        }
        
        return {
            ratesArray: ratesArray,
            serviceList: serviceList,
            dedicatedServiceList: dedicatedServiceList,
            rateZones: rateZones,
            conditionList: conditionList,
            globalUnits: globalUnits
        }
    } catch (error) {
        console.log('error', error)
        return Promise.resolve()
    }
})
const billingDataSlicer = createSlice({
    name: 'billingData',
    initialState: initialBillingState,
    reducers: {
        setBillingDataLoaded: (state, action) => {
            state.billingDataloaded = action.payload
        },
        updateBillingState: (state, { payload }) => {
            ;(state.ratesArray = payload?.ratesArray),
                (state.serviceList = payload?.serviceList),
                (state.dedicatedServiceList = payload?.dedicatedServiceList),
                (state.rateZones = payload?.rateZones),
                (state.conditionsList = payload?.conditionList),
                (state.globalUnits = payload?.globalUnits)
            state.billingDataloaded = true
        },
        setGlobalUnits: (state, action) => {
            state.globalUnits = action.payload
        },
        updateGlobalUnits: (state: any, { payload }) => {
            const { id, selected } = payload

            const index = state.globalUnits.findIndex(unit => unit.id === id);
            if (index !== -1) {
                state.globalUnits[index] = {
                    ...state.globalUnits[index],
                    data: {
                        ...state.globalUnits[index].data,
                        selected: selected                    
                    }
                };
            }
        },        
    },
    extraReducers: {
        /* fetchBillingData */
        [fetchBillingData.pending]: (state) => {
            state.billingDataloaded = false
        },
        [fetchBillingData.fulfilled]: (state, { payload }) => {
            state.ratesArray = payload?.ratesArray
            state.serviceList = payload?.serviceList
            state.dedicatedServiceList = payload?.dedicatedServiceList
            state.rateZones = payload?.rateZones
            state.conditionsList = payload?.conditionList
            state.globalUnits = payload?.globalUnits
            state.billingDataloaded = true
        },
        [fetchBillingData.rejected]: (state) => {
            state.billingDataloaded = false
        },
    },
})

export const fetchDispatchData: any = createAsyncThunk('dispatchData/fetchDidspatchData', async (thunkAPI) => {
    try {
        const [drivers, requestedWindowsCollection, driverPushStatusCollection, contractorsSnapshot, trucksSnapshot] =
            await Promise.all([
                getDocs(
                    query(
                        getProjectCollection('USERS'),
                        where('isDriver', '==', true),
                        orderBy('driverAppUserName', 'asc')
                    )
                ),
                getCollection('CONFIGURATIONS/OPTIONS/RequestedDeliveryWindow').get(),
                getCollection('CONFIGURATIONS/OPTIONS/DriverPushStatus').get(),
                contractorsCollection.get(),
                fleetCollection.get(),
            ])

        const _drivers = drivers?.docs.map((doc) => ({
            //driverAppUserName is DriverCode of Customer(Metro) order when the driver is Customer Driver, or Tag of Tookan when the driver is Shyftbase Driver only.
            value: {
                id: (doc.data().driverAppUserName || '').toString() || doc.id,
                zones: doc.data().distributionZones,
                company: doc.data().driverProfileDetails,
            },
            label: `${doc.data().firstName + ' ' + doc.data().lastName + '***' + doc.data().driverAppUserName}`,
        }))

        const requestedWindowsField: any[] =
            requestedWindowsCollection?.docs.map((x: any) => ({
                label: x.data().Name,
                value: x.data().Name,
            })) || []

        const driverPushStatusField: any[] =
            driverPushStatusCollection?.docs.map((x: any) => ({
                label: x.data().Name,
                value: x.data().Name,
            })) || []

        const _contractorsList = contractorsSnapshot?.docs.map((doc: any) => ({
            value: doc.id,
            label: doc.data().company_name || '',
        }))
        _contractorsList?.sort((a: any, b: any) => (a?.label || '').localeCompare(b?.label || ''))

        const _trucksList = trucksSnapshot?.docs
            .map((doc: any) => ({
                value: { id: doc.id, region: doc.data().Region },
                label: doc.data().Name || '',
            }))
            .filter((doc: any) => doc?.label !== '')
        _trucksList?.sort((a: any, b: any) => (a?.label || '').localeCompare(b?.label || ''))

        return {
            drivers: _drivers,
            requestedWindowsField: requestedWindowsField,
            driverPushStatusField: driverPushStatusField,
            contractorsList: _contractorsList,
            trucksList: _trucksList,
        }
    } catch (error) {
        console.log('error', error)
    }
})
const dispatchDataSlicer = createSlice({
    name: 'dispatchData',
    initialState: initialDispatchDataState,
    reducers: {
        setDispatchDataLoaded: (state, action) => {
            state.dispatchDataLoaded = action.payload
        },
        updateDispatchState: (state, { payload }) => {
            state.drivers = payload?.drivers
            state.requestedWindowsFieldList = payload?.requestedWindowsField
            state.driverPushStatusFieldList = payload?.driverPushStatusField
            state.contractorsList = payload?.contractorsList
            state.trucksList = payload?.trucksList
            state.dispatchDataLoaded = true
        },
    },
    extraReducers: {
        [fetchDispatchData.pending]: (state) => {
            state.dispatchDataLoaded = false
        },
        [fetchDispatchData.fulfilled]: (state, { payload }) => {
            state.drivers = payload?.drivers
            state.requestedWindowsFieldList = payload?.requestedWindowsField
            state.driverPushStatusFieldList = payload?.driverPushStatusField
            state.contractorsList = payload?.contractorsList
            state.trucksList = payload?.trucksList
            state.dispatchDataLoaded = true
        },
        [fetchDispatchData.rejected]: (state) => {
            state.dispatchDataLoaded = false
        },
    },
})

const selectedOrderSlicer = createSlice({
    name: 'selectedOrder',
    initialState: initialOrderState,
    reducers: {
        updateOrderState: (state, action: any) => {
            state.selectedOrder = { ...action.payload }
        },
        resetOrderState: (state) => {
            state.selectedOrder = null
        },
    },
})

export const {
    updateOrderStatusesRef,
    updateDataState,
    setDataloaded,
    setGuidesStepIndex,
    setBusinessWizzardOpen,
    updateFieldsSnapshot,
    updateInventoryFieldsnapshot,
} = dataSlicer.actions
export const { updateDispatchState, setDispatchDataLoaded } = dispatchDataSlicer.actions
export const { updateBillingState, setBillingDataLoaded, updateGlobalUnits, setGlobalUnits } = billingDataSlicer.actions
export const { updateOrderState, resetOrderState } = selectedOrderSlicer.actions

export const store = configureStore({
    reducer: {
        data: dataSlicer.reducer,
        billingData: billingDataSlicer.reducer,
        selectedOrder: selectedOrderSlicer.reducer,
        dispatchData: dispatchDataSlicer.reducer,
        user: userReducer,
    },
})
