import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    limit,
    orderBy,
    query,
    setDoc,
    updateDoc,
    where,
} from 'firebase/firestore'

class Collection {
    collectionInstance: any
    queryActions: any
    documentCollectionInstances: any
    hasDoc: any
    hasCollection: any

    constructor(collectionInstance: any) {
        this.collectionInstance = collectionInstance
        this.queryActions = []
        this.documentCollectionInstances = []
        this.hasDoc = false
        this.hasCollection = false
    }

    async get() {
        try {
            const q = query(this.collectionInstance)
            const snapshot = await getDocs(q)
            return snapshot
        } catch (er) {
            console.log(er)
        }
    }

    async add(data: any) {
        try {
            return addDoc(this.collectionInstance, data)
        } catch (err) {
            console.error(err)
            return
        }
    }

    where(fieldPath: string, opStr: any, value: any) {
        this.queryActions.push({
            type: 'where',
            data: {
                fieldPath,
                opStr,
                value,
            },
        })
        return this
    }

    limit(numLimit: number) {
        this.queryActions.push({
            type: 'limit',
            data: {
                numLimit,
            },
        })
        return this
    }

    orderBy(fieldOrder: string, direction: any) {
        this.queryActions.push({
            type: 'orderBy',
            data: {
                fieldOrder,
                direction,
            },
        })
        return this
    }

    async newDoc(data = {}) {
        const docRef = await addDoc(this.collectionInstance, data)
        await setDoc(docRef,  {id: docRef.id}, {merge: true})
        return docRef
    }

    async update(docId: string, data: any) {
        const ref = doc(this.collectionInstance, docId)
        return updateDoc(ref, data)
    }

    async doc(docId?: string) {
        if (!docId) {
            return await addDoc(this.collectionInstance, {})
        } else {
            return {
                update: async (data: any) => {
                    try {
                        const ref = doc(this.collectionInstance, docId)
                        return await updateDoc(ref, data)
                    } catch (err) {
                        console.error(err)
                        return Promise.reject(err)
                    }
                },
                collection: (collectionId: string) => ({
                    add: async (data: any) => {
                        const ref = doc(this.collectionInstance, docId)
                        const coll = collection(ref, collectionId)
                        return addDoc(coll, data)
                    },
                    where: (fieldPath: string, opStr: any, value: any) => ({
                        orderBy: (fieldOrder: string, direction: any) => ({
                            limit: (numLimit: number) => ({
                                get: async () => {
                                    const ref = doc(this.collectionInstance, docId)
                                    const coll = collection(ref, collectionId)
                                    const q = query(
                                        coll,
                                        where(fieldPath, opStr, value),
                                        limit(numLimit),
                                        orderBy(fieldOrder, direction)
                                    )
                                    return await getDocs(q)
                                },
                            }),
                        }),
                    }),
                    doc: async (docInnerId: string) => {
                        const ref = doc(this.collectionInstance, docId)
                        const coll = collection(ref, collectionId)
                        return doc(coll, docInnerId)
                    },
                }),
                get: async () => {
                    const ref = doc(this.collectionInstance, docId)
                    return await getDoc(ref)
                },
                set: async (data: any, options?: any) => {
                    const ref = doc(this.collectionInstance, docId)
                    if (options) return setDoc(ref, data, options)
                    return setDoc(ref, data)
                }
            }
        }
    }

    collection(collectionId: string) {
        this.hasCollection = true
        this.documentCollectionInstances.push({
            type: 'collection',
            name: collectionId,
        })
        return this
    }

    async set(docId: string, data: any, options: any) {
        const ref = doc(this.collectionInstance, docId)
        return setDoc(ref, data, options)
    }

    async setWithID(docId: string, data: any, options?: any) {
        const ref = doc(this.collectionInstance, docId)
        if (options) return setDoc(ref, data, options)
        return setDoc(ref, data)
    }

    async delete(docId: string) {
        const ref = doc(this.collectionInstance, docId)
        return deleteDoc(ref)
    }

    async getDocById(docId: string) {
        return getDoc(doc(this.collectionInstance, docId))
    }

    async getByQuery(fieldPath: string, opStr: any, value: any) {
        const q = query(this.collectionInstance, where(fieldPath, opStr, value))
        return await getDocs(q)
    }

    async getByQueries(
        queries: {
            fieldPath: string
            opStr: any
            value: any
        }[]
    ) {
        const toWhereQueries = queries.map((query) => where(query.fieldPath, query.opStr, query.value))
        const q = query(this.collectionInstance, ...toWhereQueries)
        return await getDocs(q)
    }

    async getByQueriesOr(
        queries: {
            fieldPath: string
            opStr: any
            value: any
        }[]
    ) {
        const toWhereQueries = queries.map((query) => where(query.fieldPath, 'array-contains', query.value))
        const q = query(this.collectionInstance, ...toWhereQueries)
        return await getDocs(q)
    }
}

export default (collection: any) => {
    return new Collection(collection)
}
