import React, { useCallback, useContext, useMemo, useState } from 'react'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'react-simple-snackbar'
import { useHistory } from 'react-router-dom'

import { auth } from '../../services/firebase/firebaseConfig'
import { useSelector } from 'react-redux'

const LoginStyles = {
    section: {
        maxWidth: '400px',
        margin: '0 auto',
        height: '100vh',
    } as React.CSSProperties,
    background: {
        backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/public-contents.appspot.com/o/SHYFTBASE%2Farno-senoner-yqu6tJkSQ_k-unsplash.jpg?alt=media&token=34c71551-b44b-4d29-b143-95f3549e483a)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '120vh',
        width: '50%',
    } as React.CSSProperties,
    signupButton: {
        backgroundColor: 'transparent',
        border: 'none',
        color: 'blue',
        padding: '0',
        font: 'inherit',
        cursor: 'pointer',
        outline: 'inherit',
    } as React.CSSProperties,
}

const SignUp = () => {

    const { userDataLoaded } = useSelector((state: any) => state.user)

    const { t } = useTranslation()

    const PASSWORD_VALIDATIONS = [
        `${t('login.rules.characters')}`, `${t('login.rules.number')}`, `${t('login.rules.uppercase')}`, `${t('login.rules.specialCharacters')}`]

    const history = useHistory()

    const [openSnackbar] = useSnackbar()
    const [password, setPassword] = useState<string>('')
    const [confirmPassword, setConfirmPassword] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [validPassword, setValidPassword] = useState<boolean>(false)
    const [validations, setValidations] = useState<boolean[]>([false, false, false, false])
    const [showPasswordGuid, setShowPasswordGuide] = useState<boolean>(false)

    const checkPassword = useCallback(
        (val: string) => {
            const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/
            const passwordValidations = [...validations]
            passwordValidations[0] = val.length >= 8
            passwordValidations[1] = /\d/.test(val)
            passwordValidations[2] = /[A-Z]/.test(val)
            passwordValidations[3] = format.test(val)

            const invalid = passwordValidations.findIndex((valid) => !valid)

            setValidations(passwordValidations)
            if (invalid > -1) {
                setValidPassword(false)
            } else {
                setValidPassword(true)
            }
        },
        [validations]
    )

    const handleEmail = useCallback(
        (e: any) => {
            setEmail(e.target.value)
        },
        [setEmail]
    )

    const handlePassword = useCallback(
        (e: any) => {
            if (e.target.value) setShowPasswordGuide(true)
            setPassword(e.target.value)
            checkPassword(e.target.value)
        },
        [setPassword, checkPassword]
    )

    const handleConfirmPassword = useCallback(
        (e: any) => {
            setConfirmPassword(e.target.value)
        },
        [setConfirmPassword]
    )

    const handleRegister = useCallback(async () => {
        try {
            await createUserWithEmailAndPassword(auth, email, password)
            history.push('/mainContainer')
        } catch (error: any) {
            openSnackbar(error && error.message)
        }
    }, [email, password])

    const disableButton = useMemo(() => {
        return !email || !validPassword || (password !== confirmPassword)
    }, [email, validPassword, password, confirmPassword])

    const renderValidations = useCallback(
        (item: any, index: number) => {
            return (
                <div className={validations[index] ? 'tag m-1 is-primary': 'tag m-1'}>
                    <p key={item} className="is-size-7">
                        {item}
                    </p>
                </div>
            )
        },
        [validations]
    )

    return (
        /* two columns div */
        <div className="columns is-centered">
            <div className="column is-6">
                <div style={LoginStyles.section} className="has-text-centered">
                    <div className="is-flex is-justify-content-space-around m-2">
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/public-contents.appspot.com/o/SHYFTBASE%2FShyftBase-Logo-smaller.png?alt=media&token=59c84d9f-ebda-4fde-b686-77be116ac340"
                            alt="shyftbase logo"
                            width={350}
                        />
                    </div>
                    <div>
                        {!userDataLoaded && (
                            <section id="firebaseui-auth-container">
                                <section style={LoginStyles.section}>
                                    <div className="field">
                                        <input className="input" type="text" placeholder="Email..." onChange={handleEmail} />
                                    </div>
                                    <div className="field container">
                                        <div className="checkmark_container" />
                                        <br/>
                                        <label className="is-size-7 has-text-weight-bold">{t('login.messages.createPassword')}</label>
                                        <input
                                            className="input"
                                            id="password"
                                            type="password"
                                            placeholder="Password..."
                                            onChange={handlePassword}
                                            onFocus={handlePassword}
                                        />
                                        {showPasswordGuid && (<div className="is-flex-wrap-wrap">
                                            {PASSWORD_VALIDATIONS.map(renderValidations)}
                                        </div>)}  
                                        <br/>
                                        <label className="is-size-7 has-text-weight-bold">{t('login.messages.confirmPassword')}</label>
                                        <input
                                            className="input"
                                            id="confirmPassword"
                                            type="password"
                                            placeholder="Password..."
                                            onChange={handleConfirmPassword}
                                            onFocus={handleConfirmPassword}
                                        />                                                                              
                                        <br/>
                                        {confirmPassword.length > 0 && password !== confirmPassword && <p className="is-size-7 has-text-danger">
                                        {t('login.messages.notMatch')}
                                            </p>}
                                    </div>
                                    <br/>
                                    <div className="recaptchaContainer container is-fluid" />
                                    <button
                                        id="sign-up"
                                        className="button field is-success is-fullwidth"
                                        onClick={handleRegister}
                                        disabled={disableButton}
                                    >
                                        {t('login.messages.createAccount')}
                                    </button>
                                    <div style={{marginTop: 200}}>
                                        <a href="https://www.iubenda.com/privacy-policy/76423059" className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe is-size-7" title="Privacy Policy ">Privacy Policy</a>
                                        <p className="is-size-7">© 2023 Shyftbase Inc. All rights reserved.</p>
                                    </div>
                                </section>
                            </section>
                        )}
                    </div>
                </div>
            </div>
            <div className="column is-6" style={LoginStyles.background}></div>
        </div>

    )
}

export default React.memo(SignUp)
