import {
    ADD_ORDERS,
    DELETE_ORDERS,
    EMPTY_CHAT_HISTORY,
    EMPTY_ORDERS,
    PUSH_MESSAGE,
    SELECT_ORDER,
    UPDATE_ORDERS,
} from './orderAction.types'

const addOrders = (payload: any, state: any) => {
    return {
        ...state,
        orders: state.orders.concat(payload),
    }
}

const updateOrders = (payload: any, state: any) => {
    console.log('payload', payload)
    console.log('state.orders:', state.orders)
    console.log(
        'state.orders.map((s: Order) => [payload].find(o => o.UniqueOrderId === s.UniqueOrderId) || s):',
        state.orders.map((s: any) => [payload].find((o) => o.UniqueOrderId === s.UniqueOrderId) || s)
    )
    return {
        ...state,
        orders: state.orders.map(
            (s: { fields: any }) => [payload].find((o) => o.fields.UniqueOrderId === s.fields.UniqueOrderId) || s
        ),
    }
}

const deleteOrders = (payload: any, state: any) => {
    return {
        ...state,
        orders: state.orders.filter((s: any) => s.UniqueOrderId !== payload.UniqueOrderId),
    }
}

const selectOrder = (payload: any, state: any) => {
    return {
        ...state,
        selectedOrder: payload,
    }
}

const pushMessage = (payload: any, state: any) => {
    return {
        ...state,
        chatHistory: state.chatHistory.concat(payload),
    }
}

const emptyChatHistory = (state: any) => {
    return {
        ...state,
        chatHistory: [],
    }
}

const emptyOrders = (state: any) => {
    return {
        ...state,
        orders: [],
    }
}

export const OrderReducer = (state: any, action: any) => {
    switch (action.type) {
        case ADD_ORDERS:
            return addOrders(action.payload, state)
        case UPDATE_ORDERS:
            return updateOrders(action.payload, state)
        case DELETE_ORDERS:
            return deleteOrders(action.payload, state)
        case SELECT_ORDER:
            return selectOrder(action.payload, state)
        case PUSH_MESSAGE:
            return pushMessage(action.payload, state)
        case EMPTY_CHAT_HISTORY:
            return emptyChatHistory(state)
        case EMPTY_ORDERS:
            return emptyOrders(state)
        default:
            return state
    }
}
