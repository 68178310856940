import {
    SELECT_TABLE_ORDER,
    SELECT_TABLE_SELECTED_DATE,
    SELECT_TABLE_SELECTED_CLIENT,
    SELECT_TABLE_SELECTED_REGION,
    UPDATE_RATE_TABLES,
    UPDATE_STEMS,
    UPDATE_ACTIONS,
    UPDATE_CONTRACTORS,
    UPDATE_RATE_CONDITIONS,
    UPDATE_ROLE,
    SET_SELECTED_VIEW,
    ADD_COUNT_FOR_FETCH
} from './tableAction.types'

import { tableViewsCollection } from '../../../../services/firebase/firebaseConfig'

export const TableAction = {
    setSelectedView: (dispatch: any, value: any) => {
        dispatch({
            type: SET_SELECTED_VIEW,
            payload: value,
        })
    },
    updateRateConditions: (dispatch: any, value: any) => {
        dispatch({
            type: UPDATE_RATE_CONDITIONS,
            payload: value,
        })
    },
    updateAllStems: (dispatch: any, value: any) => {
        dispatch({
            type: UPDATE_STEMS,
            payload: value,
        })
    },
    updateRateTables: (dispatch: any, value: any) => {
        dispatch({
            type: UPDATE_RATE_TABLES,
            payload: value,
        })
    },
    selectTableOrder: (dispatch: any, value: any) => {
        dispatch({
            type: SELECT_TABLE_ORDER,
            payload: value,
        })
    },
    selectTableSelectedDate: (dispatch: any, value: any) => {
        localStorage.setItem('selectedDate', JSON.stringify(value))
        dispatch({
            type: SELECT_TABLE_SELECTED_DATE,
            payload: value,
        })
    },
    selectTableSelectedClient: (dispatch: any, value: any) => {
        localStorage.setItem('selectedClient', JSON.stringify(value))
        dispatch({
            type: SELECT_TABLE_SELECTED_CLIENT,
            payload: value,
        })
    },
    selectTableSelectedRegion: (dispatch: any, value: any) => {
        localStorage.setItem('selectedRegion', JSON.stringify(value))
        dispatch({
            type: SELECT_TABLE_SELECTED_REGION,
            payload: value,
        })
    },
    newView: async (value: any) => {
        console.log('createView in context', value)
        return tableViewsCollection.newDoc(value)
    },
    updateView: async (value: any) => {
        await tableViewsCollection.set(value.id, value, {"merge": true})
        console.log('updateView in context', value)
    },
    deleteView: async (value: any) => {
        await tableViewsCollection.delete(value.id)
        console.log('view deleted')
    },
    updateActions: (dispatch: any, value: any) => {
        dispatch({
            type: UPDATE_ACTIONS,
            payload: value,
        })
    },
    updateRole: (dispatch: any, value: any) => {
        dispatch({
            type: UPDATE_ROLE,
            payload: value,
        })
    },
    updateContractorsList: (dispatch: any, value: any) => {
        dispatch({
            type: UPDATE_CONTRACTORS,
            payload: value,
        })
    },
    addCountForFetch: (dispatch: any) => {
        dispatch({
            type: ADD_COUNT_FOR_FETCH
        })
    }
}