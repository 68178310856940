
import {
    collection, query, getDocs, doc, where, limit, orderBy
} from 'firebase/firestore'

class Invoice {
    collectionInstance: any;
    constructor(collectionInstance: any) {
        this.collectionInstance = collectionInstance
    }

    doc(docId: string) {
        return ({
            collection: (collectionId: string) => ({
                where: (fieldPath: string, opStr: any, value: any) => ({
                    orderBy: (fieldOrder: string, direction: any) => ({
                        limit: (numLimit: number) => ({
                            get: async () => {
                                const ref = doc(this.collectionInstance, docId)
                                const coll = collection(ref, collectionId)
                                const q = query(coll, where(fieldPath, opStr, value), limit(numLimit), orderBy(fieldOrder, direction))
                                const querySnapshot = await getDocs(q)
                                return querySnapshot
                            }
                        }) 
                    })
                })
            }),
        })
    }
}

export default (collection: any) => {
    return new Invoice(collection)
}
