
import {
    addDoc
} from 'firebase/firestore'

class ActivityLog {
    collectionInstance: any;
    constructor(collectionInstance: any) {
        this.collectionInstance = collectionInstance
    }


/**
     * @param  {object} data data to be inserted
     * @returns reference 
 */

    async add(data: any) {
        try {
            return addDoc(this.collectionInstance, data)
        } catch (err) {
            console.error(err)
            return
        }
    }
}

export default (collectionInstance: any) => {
    return new ActivityLog(collectionInstance)
}