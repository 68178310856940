import { query, getDocs, doc, setDoc, addDoc, updateDoc, collection, getDoc } from 'firebase/firestore'

class Client {
    collectionInstance: any
    constructor(collectionInstance: any) {
        this.collectionInstance = collectionInstance
    }

    async get() {
        try {
            const q = query(this.collectionInstance)
            const snapshot = await getDocs(q)
            return snapshot
        } catch (er) {
            console.log(er)
        }
    }

    /**
     * @description
     * @param  {string} docId Document Id
     * @param  {any} data Data to be Updated or Added
     * @returns reference
     */

    doc(docId?: string): any {
        if (!docId) {
            return addDoc(this.collectionInstance, {}).then((data) => {
                return data
            })
        } else {
            return {
                set: async (data: any, options?: any) => {
                    const ref = doc(this.collectionInstance, docId)
                    return setDoc(ref, data, options)
                },
                update: async (data: any) => {
                    try {
                        const ref = doc(this.collectionInstance, docId)
                        return await updateDoc(ref, data)
                    } catch (err) {
                        console.error(err)
                        return
                    }
                },
                collection: (collectionId: string) => ({
                    add: async (data: any) => {
                        const ref = doc(this.collectionInstance, docId)
                        const coll = collection(ref, collectionId)
                        return addDoc(coll, data)
                    },
                }),
                get: async () => {
                    const ref = doc(this.collectionInstance, docId)
                    const document = await getDoc(ref)
                    return document
                },
            }
        }
    }
}

export default (collection: any) => {
    return new Client(collection)
}
