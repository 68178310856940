import { query, getDocs, getDoc, doc, setDoc, where } from 'firebase/firestore'

class DISTRIBUTIONZONES {
    collectionInstance: any
    constructor(collectionInstance: any) {
        this.collectionInstance = collectionInstance
    }

    async get() {
        try {
            const q = query(this.collectionInstance)
            const snapshot = await getDocs(q)
            return snapshot
        } catch (er) {
            console.log(er)
        }
    }

    doc(docId: string) {
        return {
            get: async () => {
                const ref = doc(this.collectionInstance, docId)
                const snapshot = await getDoc(ref)
                return snapshot
            },
            set: async (data: any, options?: any) => {
                const ref = doc(this.collectionInstance, docId)
                return setDoc(ref, data, options)
            },
        }
    }

    async getAvailabilityZone(region: string) {
        try {
            const q = query(this.collectionInstance, where('cdsRegion', '==', region))
            const snapshot = await getDocs(q)
            return snapshot
        } catch (er) {
            console.log(er)
        }
    }

    where(fieldPath: string, opStr: any, value: any) {
        return ({
            get: async () => {
                const q = query(this.collectionInstance, where(fieldPath, opStr, value))
                const querySnapshot = await getDocs(q)
                return querySnapshot
            },
        })
    }

    async getFirstDocByQueries(
        queries: {
            fieldPath: string
            opStr: any
            value: any
        }[]
    ) {
        const toWhereQueries = queries.map((query) => where(query.fieldPath, query.opStr, query.value))
        const q = query(this.collectionInstance, ...toWhereQueries)
        const docsRef = await getDocs(q)
        return docsRef.docs[0]
    }
}

export default (collection: any) => {
    return new DISTRIBUTIONZONES(collection)
}
