import { Icon, IconSource } from '@shopify/polaris'
import {
    ChannelsIcon,
    ChartHistogramGrowthIcon,
    DnsSettingsIcon,
    ListBulletedIcon,
    PinIcon, ProductIcon,
    QuestionCircleIcon,
} from '@shopify/polaris-icons'
import React, { useCallback, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { checkHasAccessModule } from '../../helpers/checkHasAccess'

const Nav = () => {
    useHotkeys('ctrl+shift+1', () => goToPage('/dateRegion'))
    useHotkeys('ctrl+shift+2', () => goToPage('/routes'))
    useHotkeys('ctrl+shift+3', () => goToPage('/mapFleet'))
    useHotkeys('ctrl+shift+4', () => goToPage('/reports'))
    useHotkeys('ctrl+shift+6', () => goToPage('/admin'))
    useHotkeys('ctrl+shift+l', () => setIsActive(true))

    const actions = useSelector((state: any) => state.user.actions)
    const [isActive, setIsActive] = useState(false)
    const { t } = useTranslation()
    const history = useHistory()
    const { isDarkTheme } = useSelector((state: any) => state.user)

    const goToPage = useCallback(
        (path) => {
            history.push(path)
        },
        [history]
    )

    const MainNavigations = [
        // { path: '/trackorder', label: 'nav.drawer.trackOrder', icon: ListBulletedIcon, tooltip: 'nav.tooltip.trackOrder' },
        { path: '/dateRegion', label: 'nav.drawer.orders', icon: ListBulletedIcon, tooltip: 'nav.tooltip.orders' },
        { path: '/assets', label: 'nav.drawer.assets', icon: ProductIcon, tooltip: 'nav.tooltip.assets' },
        {
            path: '/mapFleet',
            label: 'nav.drawer.mapFleet',
            icon: ChannelsIcon,
            className: 'guide-network',
            tooltip: 'nav.tooltip.mapFleet',
        },
        {
            path: '/analytics',
            label: 'nav.drawer.report',
            icon: ChartHistogramGrowthIcon,
            tooltip: 'nav.tooltip.report',
        },
        { path: '/admin', label: 'nav.drawer.admin', icon: DnsSettingsIcon, tooltip: 'nav.tooltip.admin' },
    ]

    const renderNavItem = useCallback(
        (item, index) => {
            const isSelected = (
                window.location.href.includes('records') ? '/dateRegion' : window.location.href
            ).includes(item.path)

            return (
                <div
                    className={`shyftbase_main_nav_item ${isSelected ? 'selected' : ''} ${
                        isDarkTheme ? 'dark_theme' : ''
                    } ${item.className || ''}`}
                    onClick={() => goToPage(item.path)}
                    key={`nav_${item.path}_${index}`}
                >
                    <span className="sb_tooltiptext_nav">{t(item.tooltip)}</span>
                    <Icon source={item.icon} />
                    <p>{t(item.label)}</p>
                </div>
            )
        },
        [goToPage, t]
    )

    return (
        <nav
            className={`shyftbase_nav ${isDarkTheme ? 'dark_theme' : ''}`}
            role="navigation"
            aria-label="main navigation"
        >
            <div className="shyftbase_main_nav">
                <div className={`shyftbase_main_nav_item guide-start ${isDarkTheme ? 'logo_dark_theme': 'logo_light_theme'}`}>
                    <img
                        src={
                            isDarkTheme
                                ? 'https://firebasestorage.googleapis.com/v0/b/public-contents.appspot.com/o/SHYFTBASE%2Fshyftbase_logo_light.png?alt=media&token=1db72423-63b9-4b0e-baa2-4778eea21e38'
                                : 'https://firebasestorage.googleapis.com/v0/b/public-contents.appspot.com/o/SHYFTBASE%2Fshyftbase_logo.png?alt=media&token=75278299-a954-43fd-b1f0-71904e969c56'
                        }
                        alt="Shyftbase Logo"
                        width={35}
                    />
                </div>
                <div className="shyftbase_main_nav_items">
                    {MainNavigations.filter((item) => checkHasAccessModule(item.path, actions)).map(renderNavItem)}
                </div>
                <div className="shyftbase_main_nav_item" onClick={() => history.push('/help')}>
                    <span className="sb_tooltiptext_nav">{t('nav.tooltip.help')}</span>
                    <Icon source={QuestionCircleIcon as IconSource} />
                    <p>{t('nav.drawer.help')}</p>
                </div>
            </div>
        </nav>
    )
}

export default React.memo(Nav)
