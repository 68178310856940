
import {
    getDoc, doc, setDoc
} from 'firebase/firestore'

class ReportSetting {
    collectionInstance: any;
    constructor(collectionInstance: any) {
        this.collectionInstance = collectionInstance
    }

    doc(docId?: string) {
        return ({
            get: async () => {
                const ref = doc(this.collectionInstance, docId)
                const snapshot = await getDoc(ref)
                return snapshot
            },
            set: async (data: any, options?: any) => {
                    const ref = doc(this.collectionInstance, docId)
                    return setDoc(ref, data, options)
            }
        })
    }
}

export default (collection: any) => {
    return new ReportSetting(collection)
}