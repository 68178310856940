import { createContext } from 'react'

// INITIAL STATE
export const GlobalContext = createContext({
    user: {
        displayName: '',
        countryCode: 1,
        email: '',
        firstName: '',
        isDriver: false,
        isSBCustomer: false,
        isVendor: false,
        lastName: '',
        phone: '',
        role: '',
        stores: [''],
        driverProfileDetails: '',
        clients: '',
        visibilityThreshold: '',
        regions: [''],
        user: null,
        updateUser: (data: any) => {
            return
        },
        getIdToken: () => {
            return
        },
    },
    reports: {
        generateCSV: (data: any) => {
            return null as any
        },
        generateCSVFromLive: (data: any) => {
            return null as any
        },
        generateCSVForDataExtract: (data: any) => {
            return null as any
        },
        generateCSVLiveWithComments: (data: any) => {
            return null as any
        },
    },
    orders: {
        orders: [],
        selectedOrder: null,
        chatHistory: [],
        addOrders: (value: any) => {
            return
        },
        updateOrders: (value: any) => {
            return
        },
        deleteOrders: (value: any) => {
            return
        },
        cancelOrder: (value: any) => {
            return
        },
        emptyOrders: () => {
            return
        },
        emptyChatHistory: () => {
            return
        },
        selectOrder: (value: any) => {
            return
        },
        pushMessage: (value: any) => {
            return
        },
    },
    table: {
        selectedDate: {
            from: null as any,
            to: null as any,
            mode: "lastMile" as any,
            selectedRegions: null as any,
            selectedVendors: null as any,
            dateField: null as any,
            isAllVendorSelected: false,
            isAllRegionSelected: false
        },
        countForFetch: 0,
        selectedRegion: [],
        selectedClient: [] as any,
        selectedTableOrder: [] as any,
        rateTables: {} as any,
        allStems: Map as any,
        actions: {},
        role: {},
        contractorsList: [],
        rateConditions: [],
        selectedView: { } as any,
        addCountForFetch: () => {
            return
        },
        setSelectedView: (value: any) => {
            return
        },
        updateRateConditions: (value: any) => {
            return
        },
        updateRegionIdList:(value: any) => {
            return
        },
        updateAllStems:(value: any) => {
            return
        },
        updateRateTables: (value: any) => {
            return
        },
        selectTableOrder: (value: any) => {
            return
        },
        selectTableSelectedDate: (value: any) => {
            return
        },
        selectTableSelectedRegion: (value: any) => {
            return
        },
        selectTableSelectedClient: (value: any) => {
            return
        },
        newView: (value: any) => {
            return
        },
        updateView: (value: any) => {
            return
        },
        deleteView: (value: any) => {
            return
        },
        updateActions: (data: any) => {
            return
        },
        updateRole: (data: any) => {
            return
        },
        updateContractorsList: (data: any) => {
            return
        },
    },
    ui: {
        mode: localStorage.getItem('uiMode') || 'Logistics',
        updateUIMode: (value: string) => {
            return
        },
    },
})