import { UPDATE_UI_MODE } from './uiAction.types'

export const UIAction = {
    updateUIMode: (dispatch: any, value: string) => {
        localStorage.setItem('uiMode', value)
        dispatch({
            type: UPDATE_UI_MODE,
            payload: value,
        })
    },
}
