import { UPDATE_UI_MODE } from './uiAction.types'

const selectUIMode = (payload: any, state: any) => {
    return {
        ...state,
        mode: payload,
    }
}

export const UIReducer = (state: any, action: any) => {
    switch (action.type) {
        case UPDATE_UI_MODE:
            return selectUIMode(action.payload, state)
        default:
            return state
    }
}
